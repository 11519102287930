import { initializeApp } from "firebase/app";
import { 
  initializeAppCheck, 
  ReCaptchaV3Provider 
} from "firebase/app-check";
import { 
  confirmPasswordReset, 
  getAuth, 
  /* connectAuthEmulator, */ 
  sendPasswordResetEmail,  
} from "firebase/auth";
/*
import { 
  connectDatabaseEmulator, 
  getDatabase 
} from "firebase/database";
*/
import {
  initializeFirestore,
  persistentLocalCache,
} from "firebase/firestore";
import { 
  getFunctions 
} from "firebase/functions";
import { 
  getStorage, 
  ref 
} from "firebase/storage";
// import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCJTHfAcgIFziFs2GOSNtGRWa5JiF1T2Do",
  authDomain: "mahalogolfing.firebaseapp.com",
  databaseURL: "https://mahalogolfing-default-rtdb.firebaseio.com",
  projectId: "mahalogolfing",
  storageBucket: "mahalogolfing.appspot.com",
  messagingSenderId: "246675771480",
  appId: "1:246675771480:web:af152b8d42fb79e802fa2d",
  measurementId: "G-DDJ4763QQ8",
};

export const passwordReset = async (email: string) => {
  return await sendPasswordResetEmail(auth, email)
}

export const confirmThePasswordReset = async (
  oobCode:string, newPassword:string
) => {
  if(!oobCode && !newPassword) return;
  
  return await confirmPasswordReset(auth, oobCode, newPassword)
}

const firebaseApp = initializeApp(firebaseConfig);
let appCheck = null;

declare global {
  // eslint-disable-next-line no-var
  var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined;
}

if (!process.env.NODE_INV || process.env.NODE_ENV === "development") {
  if (typeof window !== 'undefined') {
    window.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_APPCHECK_DEBUG_TOKEN;
  } 
}

// For development on a local host, no need to activate app check.
if (typeof window !== "undefined" && !appCheck) {
  appCheck = initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaV3Provider("6Lck4lIpAAAAAKLUo-cIE4rKsSIVuzjLx_f_Jq2s"),
      // Optional argument. If true, the SDK automatically refreshes App Check
      //  tokens as needed.
      isTokenAutoRefreshEnabled: true
  });
}
 
export const auth = getAuth(firebaseApp);
export const firestore = initializeFirestore(firebaseApp, {localCache: persistentLocalCache({})});
export const cloudFunctions = getFunctions(firebaseApp);

export const appCheckInstance = appCheck;

// export const analytics = getAnalytics(firebaseApp);
export const storage = getStorage(firebaseApp);
export const storageRef = ref(storage);
export const imagesRef = ref(storage, "images");
// connectAuthEmulator(auth, "http://localhost:9099");
