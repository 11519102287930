import { ReactElement } from "react";

import { ref } from "firebase/storage";

import { NavLink } from "react-router-dom";

import { useDownloadURL } from "react-firebase-hooks/storage";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import GolfCourseIcon from "@mui/icons-material/GolfCourse";

import { imagesRef } from "../../firebase";
import Course from '../../types/Course';

interface CourseRowProps {
  course: Course,
  actions?: ReactElement<typeof Stack>
}

export const CourseRow = (props: CourseRowProps ) : ReactElement => {

  const course = props.course;
  const [imageUrl] = useDownloadURL((course && course.logo && course.logo.length > 0) ? ref(imagesRef, course.logo) : null);

  const StyledName = (): ReactElement => {
    return (<>
      <Grid m={4} sx={{py: 0, my: 0}} size={12}>
        <Box textAlign="left" alignItems="flex-start">
          <Typography variant="h6" color="primary.dark" sx={{py: 0, my: 0, lineHeight: "normal" }}>
            {course.name}
          </Typography>
        </Box>
      </Grid>
    </ >);
  }

  const StyledBasicInfo = (): ReactElement => {
    return (
      (<Grid container justifyContent="flex-end" size={6}>
        <Grid size={12}>
          <Typography variant="overline" color="primary" sx={{py: 0, my: 0, lineHeight: 1 }}>
            {"Par: " + course.par}
          </Typography>
        </Grid>
        <Grid size={12}>
          <Typography variant="caption" color="primary" sx={{py: 0, my: 0, lineHeight: 1 }}>
            {"Tee time spacing: " + course.teeTimeSpacing}
          </Typography>
        </Grid>
      </Grid>)
    );
  }

  return (
    (<Grid
      container
      key={course.itemId}
      alignItems="center"
      component={NavLink}
      to={`/course/${course.itemId}`}
      state={{isNewCourse: false}}
      sx={{py: 4, borderBottom: 1, textDecoration: 'inherit'}}
      size={12}>
      <Grid
        justifyContent="center"
        sx={{ display: { xs: "none", sm: "flex"}}}
        size={2}>
        <Avatar 
          alt={course.name} 
          src={imageUrl}>
          <GolfCourseIcon />
        </Avatar>
      </Grid>
      <Grid container size={5}>
        <StyledName />
      </Grid>
      <Grid
        container
        aria-label="view a person's basic info"
        style={{ }}
        size={{
          xs: 7,
          sm: 5
        }}>
        <StyledBasicInfo />
      </Grid>
      {props.actions &&
        <Grid size={12}>
          {props.actions}
        </Grid>
      }
    </Grid>)
  );
}