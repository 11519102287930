import { ReactElement, useRef, useState } from 'react';

import { UploadResult } from 'firebase/storage';

import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid2';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import SaveIcon from '@mui/icons-material/Save';

import dayjs, { Dayjs } from 'dayjs';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import { useAuth } from '../../AuthProvider';
import { useData } from '../../DataProvider';
import Competition from '../../types/Competition';
import Event from '../../types/Event';
import EventDay from '../../types/EventDay';
import League from '../../types/League';
import { CombineDateJSTimeJS, FromDayJS, ToDayJS } from '../../utils/utils';
import GratiImageUpload from '../common/GratiImageUpload';
import CompetitionEditor from '../competition/CompetitionEditor';

import EventDateCalendar from './EventDateCalendar';
import EventTimeEditor from './EventTimeEditor';

export interface EventEditorProps {
  event: Event;
  isNewEvent: boolean;
  onCancel?: () => void;
  onSubmit?: (event: Event) => void;
}

export default function EventEditor(props: EventEditorProps): ReactElement {
  const { event, isNewEvent, onCancel, onSubmit } = props;

  const { isRevealingGodsSecrets } = useAuth();

  const { deleteEvent, isLeagueAdmin, isOrgAdmin, leagues } = useData();

  const navigate = useNavigate();

  const [updatedEvent, setUpdatedEvent] = useState<Event>(event);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [isMultiDay, setIsMultiDay] = useState<boolean>(event.days.length > 1);
  const [isGolf, setIsGolf] = useState<boolean>(event.isGolf ?? false);

  const [defaultTime, setDefaultTime] = useState<Dayjs>(
    event && event.days.length > 0 && event.days[0].datetime
      ? ToDayJS(event.days[0].datetime, event.timezone)
      : dayjs('2000-01-01T09:00:00')
  );
  const defaultCourseId = useRef(
    event && event.days.length > 0 && event.days[0].courseId ? event.days[0].courseId : ''
  );

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    } else {
      navigate(-1);
      console.log('No cancel handler provided.');
    }
  };

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit(updatedEvent);
    } else {
      console.log('No submit handler provided.');
    }
  };

  //Todo: Write the logic to move events to seasons.
  const handleCopyToSeason = () => {
    if (updatedEvent) {
      console.log('Will eventually use this to migrate events to seasons.');
      // copyEventToSeason(event);
    }
  };

  const handleImageUploaded = (snapshot: UploadResult) => {
    if (updatedEvent) {
      setUpdatedEvent({ ...updatedEvent, logo: snapshot.ref.name });
    }
    console.log('Image uploaded: ' + snapshot.ref.name);
  };

  const handleNameChange = (target: EventTarget) => {
    const name = (target as HTMLInputElement).value;
    if (updatedEvent) {
      setUpdatedEvent({ ...updatedEvent, name: name });
    }
  };

  const deleteThisEvent = (id: string) => {
    console.log(`Deleting event ${id}`);
    deleteEvent(id);
    setDeleteConfirm(false);
    navigate('/');
  };

  const handleEventDateChange = (dates: Dayjs[] | null) => {
    if (updatedEvent) {
      setIsGolf(updatedEvent.isGolf ?? false);
      if (!dates || dates.length === 0) {
        setUpdatedEvent({ ...updatedEvent, datetime: FromDayJS(null), days: [] });
      } else if (updatedEvent.days.length === 0) {
        setUpdatedEvent({
          ...updatedEvent,
          datetime: FromDayJS(dates[0]),
          days: dates.map((date) => ({
            datetime: CombineDateJSTimeJS(date, defaultTime),
            courseId: defaultCourseId.current,
            duration: 60,
            isGolf: updatedEvent.isGolf,
          })),
        });
      } else {
        const newDays = dates.map((date, i) => {
          const existingDay = updatedEvent.days.find((day) => ToDayJS(day.datetime).isSame(date, 'D'));
          if (existingDay) {
            return existingDay;
          } else {
            return {
              datetime: CombineDateJSTimeJS(date, defaultTime),
              courseId: defaultCourseId.current,
              duration: 60,
              isGolf: updatedEvent.isGolf,
            };
          }
        });
        setUpdatedEvent({ ...updatedEvent, datetime: newDays[0].datetime, days: newDays });
      }
    }
  };

  const handleEventTimesChange = (newDays: EventDay[] | null) => {
    if (updatedEvent) {
      if (newDays) {
        setUpdatedEvent({ ...updatedEvent, datetime: newDays[0].datetime, days: newDays });
        setDefaultTime(ToDayJS(newDays[0].datetime, props.event.timezone));
      }
    }
  };

  const handleCompetitionChange = (competition: Partial<Competition>) => {
    if (updatedEvent) {
      const competitions = updatedEvent.competitions;
      if (competitions?.length) {
        console.log('Competition changed: ' + competitions[0].itemId);
      }
    }
  };

  function Competitions(): ReactElement {
    if (!updatedEvent || !updatedEvent.competitions) {
      return <Button>Add Competition</Button>;
    } else {
      return (
        <Stack spacing={2}>
          {updatedEvent.competitions.map((competition) => (
            <CompetitionEditor key={competition.itemId} competition={competition} onChange={handleCompetitionChange} />
          ))}
        </Stack>
      );
    }
  }

  if (updatedEvent) {
    return (
      <Box component="form" onSubmit={(e) => { e.preventDefault(); handleSubmit();}}>
        <Stack>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, sm: 6, }}>
              <Stack spacing={2}>
              <TextField
                required
                fullWidth
                id="name"
                label="Name"
                aria-label="name"
                aria-required="true"
                value={updatedEvent.name}
                onChange={({ target }) => handleNameChange(target)}
              />
              {isOrgAdmin(updatedEvent.orgId) && leagues && leagues.length > 0 && (
                <TextField
                  id="league"
                  label="League"
                  autoFocus={true}
                  required
                  fullWidth
                  select
                  aria-label="League"
                  aria-required="true"
                  value={updatedEvent.leagueId}
                  onChange={({ target }) => {
                    let leagueName = '';
                    const matchingLeague = leagues?.find((league) => league.itemId === target.value);
                    if (matchingLeague) {
                      leagueName = matchingLeague.name;
                    }
                    setUpdatedEvent({ ...updatedEvent, leagueId: target.value, leagueName: leagueName });
                  }}
                >
                  {leagues &&
                    leagues.map((league: League) => (
                      <MenuItem key={league.itemId} value={league.itemId}>
                        {league.name}
                      </MenuItem>
                    ))}
                </TextField>
              )}
              {!isOrgAdmin(updatedEvent.orgId) && isLeagueAdmin(updatedEvent.leagueId) && (
                <TextField
                  id="league-disabled"
                  autoFocus={true}
                  label="League"
                  aria-label="League"
                  value={updatedEvent.leagueName}
                  disabled
                />
              )}
              <TextField
                id="description"
                label="Description"
                aria-label="description"
                aria-required="false"
                fullWidth
                value={updatedEvent.description}
                onChange={({ target }) => setUpdatedEvent({ ...updatedEvent, description: target.value })}
              />
              <GratiImageUpload
                onImageUploaded={handleImageUploaded}
                storageImageFileName={updatedEvent.itemId + '-logo'}
                label="Logo"
              />
              <Stack direction="row" spacing={2} sx={{display: "flex", alignItems: "center"}}>
                <DateTimePicker
                  label="Sign Up Begins at"
                  aria-label="sign up opens at"
                  aria-required="true"
                  format="MM/DD/YY hh:mm a"
                  value={ToDayJS(updatedEvent.signupOpensAt, updatedEvent.timezone)}
                  onChange={(target) => {
                    if (target != null) {
                      setUpdatedEvent({
                        ...updatedEvent,
                        signupOpensAt: FromDayJS(target),
                      });
                      if (FromDayJS(target) > updatedEvent.signupClosesAt) {
                        setUpdatedEvent({
                          ...updatedEvent,
                          signupClosesAt: FromDayJS(target),
                        });
                      }
                    }
                  }}
                />
                <DateTimePicker
                  label="Sign Up Deadline"
                  aria-label="sign up closes at"
                  aria-required="true"
                  format="MM/DD/YY hh:mm a"
                  value={ToDayJS(updatedEvent.signupClosesAt, updatedEvent.timezone)}
                  onChange={(target) => {
                    if (target != null) {
                      setUpdatedEvent({
                        ...updatedEvent,
                        signupClosesAt: FromDayJS(target),
                      });
                    }
                  }}
                />
              </Stack>
              <Stack direction="row" spacing={2} sx={{display: "flex", alignItems: "center"}}>
              <ToggleButtonGroup
                color="primary"
                value={updatedEvent.isGolf ? "golf" : "social"}
                exclusive
                onChange={(e, value) => {
                  if (value) {
                    setIsGolf(value === "golf");
                    setUpdatedEvent({
                      ...updatedEvent,
                    isGolf: value === "golf",
                  })};
                }}
                size="small"
                aria-label="Event Type"
                >
                  <ToggleButton value="golf">Golf</ToggleButton>
                  <ToggleButton value="social">Social</ToggleButton>
                </ToggleButtonGroup>
                <ToggleButtonGroup
                  color="primary"
                  value={isMultiDay ? "multiday" : "oneday"}
                  exclusive
                  onChange={(e, value) => {
                    if (value) {
                    console.log('Is multi-day: ' + value);
                      setIsMultiDay(value === "multiday");
                    }
                  }}
                  size="small"
                  aria-label="Is Multi-day"
                >
                  <ToggleButton value="oneday">Single Day</ToggleButton>
                  <ToggleButton value="multiday">Multi-day</ToggleButton>
                </ToggleButtonGroup>
              </Stack>
            </Stack>
            </Grid>

            <Grid size={{xs: 12, sm: 6}}>
              <Stack spacing={2}>
              {updatedEvent && isGolf && (

                <Stack direction="row" spacing={2} sx={{display: "flex", alignItems: "center"}}>
                  <TextField
                    id="numteetimes"
                    label="Tee Times"
                    aria-label="Number of Tee Times"
                    aria-required="true"
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    value={updatedEvent.numTeeTimes}
                    onChange={({ target }) => {
                      const numTeeTimes = parseInt(target.value !== '' ? target.value : '0', 10);
                      setUpdatedEvent({
                        ...updatedEvent,
                        numTeeTimes: numTeeTimes,
                        maxPlayers: numTeeTimes * 4,
                      });
                    }}
                  />
                  <TextField
                    id="maxPlayers"
                    label="# of Golfers"
                    aria-label="Max number of golfers"
                    aria-required="false"
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    value={updatedEvent.maxPlayers}
                    onChange={({ target }) =>
                      setUpdatedEvent({
                        ...updatedEvent,
                        maxPlayers: parseInt(target.value, 10),
                      })
                    }
                  />
                </Stack>)}
              <EventDateCalendar
                isMultiDay={isMultiDay}
                onChange={handleEventDateChange}
                orientation="portrait"
                value={updatedEvent.days.map((day) => ToDayJS(day.datetime, updatedEvent.timezone))}
              />
              <EventTimeEditor
                days={updatedEvent.days}
                defaultTime={defaultTime}
                isGolfEvent={isGolf}
                onChange={handleEventTimesChange}
                timezone={updatedEvent.timezone}
              />
            {updatedEvent && !updatedEvent.isGolf && (
                <TextField
                  id="socialLocation"
                  autoFocus={false}
                  label="Social Location"
                  arial-label="Location of social event"
                  aria-required="false"
                  value={updatedEvent.socialLocation}
                  onChange={({ target }) => setUpdatedEvent({ ...updatedEvent, socialLocation: target.value })}
                />
            )}
            {((updatedEvent && updatedEvent.isGolf) || isRevealingGodsSecrets) && (
                  <Stack direction="row" spacing={2} sx={{display: "flex", alignItems: "center"}}>
                    {updatedEvent?.isGolf && 
                      <FormControl component="fieldset">
                        <FormControlLabel
                          value="top"
                          aria-label="member member"
                          aria-required="false"
                          control={
                            <Checkbox
                              checked={updatedEvent.isMemberMember}
                              name="Member Member"
                              onChange={({ target }) => setUpdatedEvent({ ...updatedEvent, isMemberMember: target.checked })}
                            />
                          }
                          label="Member/Member?"
                          labelPlacement="top"
                        />
                      </FormControl>
                    }
                    {updatedEvent?.isGolf && 
                      <FormControl component="fieldset">
                        <FormControlLabel
                          value="top"
                          aria-label="member guest"
                          aria-required="false"
                          control={
                            <Checkbox
                              checked={updatedEvent.isMemberGuest}
                              name="Member Guest"
                              onChange={({ target }) => setUpdatedEvent({ ...updatedEvent, isMemberGuest: target.checked })}
                            />
                          }
                          label="Member/Guest?"
                          labelPlacement="top"
                        />
                      </FormControl>
                  }
                  {isRevealingGodsSecrets && (
                    <FormControl component="fieldset">
                    <FormControlLabel
                      value="top"
                      aria-label="is a test event"
                      aria-required="false"
                      control={
                        <Checkbox
                          checked={updatedEvent.isTest}
                          name="Test Event"
                          onChange={({ target }) => setUpdatedEvent({ ...updatedEvent, isTest: target.checked })}
                        />
                      }
                      label="Test?"
                      labelPlacement="top"
                    />
                  </FormControl>
              )}
                </Stack>)}
                </Stack>
              </Grid>
            </Grid>
          <Stack spacing={2}>
            <Competitions />
          </Stack>

          <Stack direction="row" spacing={2}>
            <Button
              type="submit"
              key="formSubmit"
              size="medium"
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
            >
              {isNewEvent ? 'Create Event' : 'Update Event'}
            </Button>
            <Button
              size="medium"
              variant="outlined"
              color="secondary"
              startIcon={<CancelIcon />}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            {isOrgAdmin(updatedEvent.orgId) && (
              <>
                <Button
                  type="button"
                  key="formDelete"
                  variant="outlined"
                  color="secondary"
                  aria-label="delete"
                  size="medium"
                  onClick={() => setDeleteConfirm(true)}
                  startIcon={<DeleteIcon />}
                >
                  Delete Event
                </Button>
                {deleteConfirm && (
                  <span>
                    <Button
                      type="button"
                      key="deleteConfirm"
                      size="medium"
                      onClick={() => deleteThisEvent(updatedEvent.itemId)}
                    >
                      Confirm
                    </Button>
                    <Button type="button" key="deleteCancel" size="medium" onClick={() => setDeleteConfirm(false)}>
                      Cancel
                    </Button>
                  </span>
                )}
                {isRevealingGodsSecrets && (
                  <Button
                    type="button"
                    key="formMove"
                    variant="outlined"
                    aria-label="move"
                    size="large"
                    onClick={() => handleCopyToSeason()}
                    startIcon={<MoveDownIcon />}
                  >
                    Copy to Season 2023
                  </Button>
                )}
              </>
            )}
          </Stack>
        </Stack>
      </Box>
    );
  } else {
    return <Container />;
  }
}
