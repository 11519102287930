import { 
  Route, 
  Routes 
} from "react-router-dom";

import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import type {} from '@mui/material/themeCssVarsAugmentation';

import dayjs from 'dayjs';

import { Divider, responsiveFontSizes } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { useAuth } from "./AuthProvider";
import GratiAppBar from "./components/common/GratiAppBar";
import GratiNavRail from "./components/common/GratiNavRail";
import Hourglass from "./components/common/Hourglass";
import PrivateRoute from "./components/common/PrivateRoute";
import { useData } from "./DataProvider";
import Apply from "./pages/Apply";
import CourseCreatePage from "./pages/CourseCreatePage";
import CoursePage from "./pages/CoursePage";
import Courses from "./pages/Courses";
import EmailVerified from "./pages/EmailVerified";
import EventCreatePage from "./pages/EventCreatePage";
import EventPage from "./pages/EventPage";
import Events from "./pages/Events";
import ForgotPassword from "./pages/ForgotPassword";
import Guests from "./pages/Guests";
import Handbook from "./pages/Handbook";
import Home from "./pages/Home";
import InviteMembers from "./pages/InviteMembers";
import LeagueCreatePage from "./pages/LeagueCreatePage";
import LeaguePage from "./pages/LeaguePage";
import Leagues from "./pages/Leagues";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Members from "./pages/Members";
import PersonPage from "./pages/PersonPage";
import ResetPassword from "./pages/ResetPassword";
import Signup from "./pages/Signup";
import Verify from "./pages/Verify";
import Welcome from "./pages/Welcome";
import mui6Theme from "./styles/mui6Theme";
import "./styles/App.css";

function App() {
  // useMediaQuery('(prefers-color-scheme: dark)') ? 'dark' : 'light'
  const navRailWidth = '5rem';
  const { isGod, isLoggedIn, isLoggingIn, isRevealingGodsSecrets, revealGodsSecrets } = useAuth();
  const { isAdminDisabled, adminDisabled, isUserLoading } = useData();

  dayjs.extend(utc);
  dayjs.extend(timezone);

  const responsiveMui6Theme = responsiveFontSizes(mui6Theme);
  const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={responsiveMui6Theme} defaultMode="system">
        <Box sx={{ display: "flex", flexGrow: 1}}>
          {isLoggedIn &&
            <GratiAppBar sx={{ zIndex: (theme) => theme.zIndex.drawer + 1}}/>
          }
          {isLoggedIn && 
            <GratiNavRail sx={{display: {xs: "none", md: "flex"}}}/>
          }
          {isLoggingIn || isUserLoading ? 
            <Hourglass /> :
            <Stack 
              position="sticky" 
              className="AppStack" 
              sx={{ display: "flex", flexGrow: 1, spacing: 1, justifyContent: "flex-start", width: {xs: '100vw', md: `calc(100vw - ${navRailWidth})`}, backgroundColor: "surface.main",}}>
              <Offset />
              <Box sx={{p: 1}}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/course/:courseId" element={<PrivateRoute><CoursePage /></PrivateRoute>} />
                <Route path="/course-create" element={<PrivateRoute><CourseCreatePage /></PrivateRoute>} />
                <Route path="/courses" element={<PrivateRoute><Courses /></PrivateRoute>} />
                <Route path="/course-edit/:courseId" element={<PrivateRoute><CoursePage edit={true}/></PrivateRoute>} />
                <Route path="/upcoming-events" element={<PrivateRoute><Events initialTab={"1"}/></PrivateRoute>} />
                <Route path="/past-events" element={<PrivateRoute><Events initialTab={"2"} /></PrivateRoute>} />
                <Route path="/emailverified" element={<EmailVerified />} />
                <Route path="/event/:eventId" element={<PrivateRoute><EventPage isEditMode={false}/></PrivateRoute>} />
                <Route path="/event-create" element={<PrivateRoute><EventCreatePage /></PrivateRoute>} />
                <Route path="/event-edit/:eventId" element={<PrivateRoute><EventPage isEditMode={true}/></PrivateRoute>} />
                <Route path="/attendees-edit" element={<PrivateRoute><EventPage isEditMode={false}/></PrivateRoute>} />
                <Route path="/events" element={<PrivateRoute><Events /></PrivateRoute>} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="/guests" element={<PrivateRoute><Guests /></PrivateRoute>} />
                <Route path="/handbook" element={<PrivateRoute><Handbook /></PrivateRoute>} />
                <Route path="/home" element={<Home />} />
                <Route path="/apply" element={<Apply />} />
                <Route path="/login" element={<Login />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/league/:leagueId" element={<PrivateRoute><LeaguePage isEditMode={false}/></PrivateRoute>} />
                <Route path="/league-create" element={<PrivateRoute><LeagueCreatePage /></PrivateRoute>} />
                <Route path="/league-edit/:leagueId" element={<PrivateRoute><LeaguePage isEditMode={true} /></PrivateRoute>} />
                <Route path="/leagues" element={<PrivateRoute><Leagues /></PrivateRoute>} />
                <Route path="/members" element={<PrivateRoute><Members initialTab={"1"}/></PrivateRoute>} />
                <Route path="/applications" element={<PrivateRoute><Members initialTab={"2"} /></PrivateRoute>} />
                <Route path="/invite-members" element={<PrivateRoute><InviteMembers /></PrivateRoute>} />
                <Route path="password-reset" element={<ResetPassword />} />
                <Route path="/profile" element={<PrivateRoute><PersonPage isEditMode={false} /></PrivateRoute>} />
                <Route path="/profile/:profileId" element={<PrivateRoute><PersonPage isEditMode={false} /></PrivateRoute>} />
                <Route path="/profile-edit/:profileId" element={<PrivateRoute><PersonPage isEditMode={true} /></PrivateRoute>} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/verify" element={<Verify />} />
                <Route path="/welcome" element={<Welcome />} />
              </Routes>
              {isGod && (
                <Stack sx={{
                  display: 'flex', 
                  flexGrow: 1, 
                  mt: 2, 
                  backgroundColor: "tertiary.container", 
                  color: "tertiary.onContainer"}}
                >
                  <Divider />
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch id={"enable_god"}
                          checked={isRevealingGodsSecrets}
                          onChange={(e) => {
                            revealGodsSecrets(e.target.checked);
                          }}
                        />
                      }
                      label="Reveal God's Secrets"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch id={"disable_admin"}
                          checked={isAdminDisabled}
                          onChange={(e) => {
                            adminDisabled(e.target.checked);
                          }}
                        />
                      }
                      label={"Disable Admin"}
                    />
                  </FormGroup>
                </Stack>
              )}
              </Box>
            </Stack>
          }
        </Box>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;