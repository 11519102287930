import { 
  ReactElement, 
  useEffect, 
} from "react";

import { 
  collection, 
  Query, 
  query,
  where
} from "firebase/firestore";

import { useCollectionData } from "react-firebase-hooks/firestore";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";

import { firestore } from '../../firebase';
import { CompDenomination } from "../../types/Competition";
import Score from "../../types/Score";
import Season from "../../types/Season";
import { GratiScorecard } from "../common/GratiScorecard";

type LeagueResultsProps = {
  leagueId: string;
  orgId: string;
  memberId?: string;
};

export default function LeagueResults(props: LeagueResultsProps ): ReactElement {
  const memberId = props.memberId;

  const qResults = query(
    collection(firestore, 
      "org", props.orgId, 
      "season"), 
      where ("leagueId", "==", props.leagueId),
  ) as Query<Season>;

  const [seasons, isSeasonsLoading, seasonsError] = useCollectionData<Season>(qResults);

  useEffect (() => {
    if (seasons) {
      console.log("Seasons loaded.");
    } else if (seasonsError) {
      console.log("Error loading results: " + seasonsError.message);
    } else if (isSeasonsLoading) {
      console.log("Loading results.");
    }
  }, [seasons, seasonsError, isSeasonsLoading]);

  function filterPlayers (score: Score): boolean {
    if (score) { 
      return (memberId ? score.playerId === memberId : true);
    } else {
      return false;
    }
  }

  const Standings = (props: {season: Season}) => {
    const season = props.season;
    console.log(season);
    if (season.standings && season.standings.length > 0) {
      return (
        <Stack spacing={1} sx={{display: "flex", justifyContent:"flex-start", flexGrow: 0}}>
          {season.standings.map((standing) =>
            standing.scores &&
              <GratiScorecard 
                key={standing.competition.name}
                title={standing.competition.name}
                columns={              
                  {
                    fields: standing.competition.denomination === CompDenomination.Stroke ? 
                      "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18" : "Cash",
                    fieldLabels: standing.competition.denomination === CompDenomination.Stroke ? 
                      "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18" : "Cash",
                    holePars: standing.competition.denomination === CompDenomination.Stroke ? 
                      standing.courseParByHole : undefined, 
                  }
                } 
                rows={standing.scores} filterFunction={filterPlayers}
                denomination={standing.competition.denomination}/>
          )}
        </Stack>
      );
    } else { 
      return <Box key={season.itemId}></Box>
    }
  };  

  if (!isSeasonsLoading && seasons) {
    return (
      <Container disableGutters className="BodyContainer" sx={{ flexGrow: 1}}>
        {seasons.map((season) => 
          <Standings key={"standings" + season.itemId} season={season} />
        )}
      </Container>
    );
  } else {
    return <> </>
  }
}