

// What is the unit used in scoring? 
export const enum CompDenomination {
  Cash = "cash",
  Point = "point",
  Stroke = "stroke",
}

// What is the team / competitor structure?
export const enum CompEntry {
  Club = "club",                    // Golf clubs are competing against each other
  FourCouples = "fourcouples",      // Four couples per team.
  Foursome = "foursome",            // Four golfers per team.
  Group = "group",                  // A set # of golfers per team that isn't 1, 2, 4, or 8.
  Single = "single",                // Individual competition
  TwoCouples = "twocouples",        // Two couples per team.
  Wheel = "wheel",                  // Parters rotate throughout the round. 
}

// What is the field?
export const enum CompField {
  All = "all",                      // All golfers are competing against each other.
  Flight = "flight",                // Golfers are divided into flights and compete within the flight.
  Foursome = "foursome",            // Golfers are competing within their foursome. For example, 4 ball match play.  
}

export const enum CompFormat {
  AlternateShot = "alternateshot",  // Team members take turns hitting the same ball.
  BestBall = "bestball",            // Team members take their best individual score for each hole.
  Chapman = "chapman",              // Each team member tees off, then they hit each other's ball, then pick the best ball and play alternate shot.   
  Scramble = "scramble",            // Each team member hits a shot, then the team picks the best shot and all play from there. This repeats until the hole is finished.
  Shamble = "shamble",              // Each team member hits a shot, then the team picks the best shot and play best ball from there.
  Total = "total",                  // All team members scores count and are summed. 
}

export const enum CompHandicap {
  Gross = "gross",                  // No handicap is applied.
  Net = "net",                      // Playing Handicap is applied.
}  

export const enum CompHoleTies {
  Roll = "roll",                    // If no one wins a hole, the hole winnings are rolled over to the next hole.
  Split = "split",                  // Winners split the hole winnings.
  Tie = "tie",                      // No one wins anything.    
}

export const enum CompScoresThatCount {
  All = "all",                       // All scores count.  
  Birdies = "birdies",               // Only birdies or better count.
  Deuces = "deuces",                 // Only twos or better count.
  Eagles = "eagles",                 // Only eagles or better count.
  Pars = "pars",                     // Only pars or better count.
  Treys = "treys",                   // Only threes or better count.
}

export const enum CompScoring {
  Eclectic = "eclectic",            // Best score on each hole over multiple rounds counts.
  Match = "match",                  // Win a hole, win a point. Tie a hole, split a point.
  Nassau = "nassau",                // Win the front 9, win a point. Win the back 9, win a point. Win the 18, win a point.
  SkinsBasic = "skins-basic",       // Sole winner of a hole, wins a skin. Any ties, the skin is rolled over to the next hole.
  SkinsCanadian = "skins-canadian", // SOle winner of a hole, wins a skin. If two tie, but one has a lower gross score, they win.
  SkinsSilk = "skins-silk",         // Team skins. If two players on one team tie one player on another team, the team with the two scores win.
  Stableford = "stableford",        // Points are awarded based on score relative to par.
  Stroke = "stroke",                // Each stroke counts.     
}

export const CompStructure = {
  Bracket: "bracket",              // Competitors are paired off and play head to head. Winners advance.
  Event: "event",                  // One day or multiple day event. 
  League: "league",                // Season long competition with a defined set of competitors.
  RoundRobin: "roundrobin",        // Each competitor plays every other competitor.
}

export const enum CompTiebreaker {
  ByHandicap = "byhandicap",       // Scores are compared based on most difficult holes. First player to have a lower score wins.  
  Back9 = "back9",                 // Total back 9 score is the tiebreaker.   
  Custom = "custom",               // user-defined
  Front9 = "front9",               // Total front 9 score is the tiebreaker.
  Last1 = "last1",                 // Last hole score is the tiebreaker.
  Last3 = "last3",                 // Last 3 hole scores are the tiebreaker.    
  Last6 = "last6",                 // Last 6 hole scores are the tiebreaker.
  Last9 = "last9",                 // Last 9 hole scores are the tiebreaker.
  None = "none",                   // No tiebreaker.
  Playoff = "playoff",             // Sudden death playoff. 
}

export default interface Competition {
  itemId: string;
  denomination?: CompDenomination;
  field?: CompField;
  entry?: CompEntry;
  format?: CompFormat;
  handicap?: CompHandicap;
  name?: string;
  scoresThatCount?: CompScoresThatCount;
  scoring?: CompScoring;
  structure?: string;
  tiebreaker?: CompTiebreaker[];
  tiebreakerCustom?: string;
}