import { 
  ReactElement, 
  useState 
} from "react";

import { Link } from "react-router-dom";

import Button from "@mui/material/Button";
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { useData } from "../../DataProvider";
import League from '../../types/League';
import GratiCard from "../common/GratiCard";

interface LeagueCardProps {
  league: League;
  variant?: "elevation" | "outlined" | "none";
}

export default function LeagueCard(props: LeagueCardProps): ReactElement {
  const { league } = props;
  const variant = props.variant ?? "outlined";
  const { deleteLeague, isOrgAdmin } = useData();

  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const deleteThisLeague = (id: string) => {
    console.log(`Deleting event ${id}`);
    deleteLeague(id);
    setDeleteConfirm(false);
  };


  const StyledType = (): string => {
    return (
      league?.competitionLevel && league.competitionLevel > 4 ? "Competitive" : "Casual"
    );
  }

  const ActionBar = (): ReactElement => {
    return (
      <Stack direction="row" spacing={1}>
        <IconButton
          component={Link}
          to={`/league-edit/${league.itemId}`}
          state={{ leagueId: league.itemId }}
          aria-label="edit"
        >
          <EditIcon />
        </IconButton>
        <IconButton
          aria-label="delete"
          onClick={() => setDeleteConfirm(true)}
        >
          <DeleteIcon />
        </IconButton>
        {deleteConfirm && (
          <span>
            <Button
              size="small"
              onClick={() => deleteThisLeague(league.itemId)}
            >
              Confirm
            </Button>
            <Button size="small" onClick={() => setDeleteConfirm(false)}>
              Cancel
            </Button>
          </span>
        )}
      </Stack>
    );
  }

  return (
    <GratiCard 
      to={`/league/${league.itemId}`} 
      key={league.itemId}
      variant={variant}
      overline={StyledType()}
      avatar={league.logo}
      title={league.name} 
      description={league.description}
      actionbar={isOrgAdmin(league.orgId) ? <ActionBar /> : undefined}
    />
  );
}