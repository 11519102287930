import { ReactElement, useEffect } from 'react';

import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';

import { useAuth } from '../AuthProvider';
import Hourglass from '../components/common/Hourglass';
import EventCard from '../components/event/EventCard';
import EventSchedule from '../components/event/EventSchedule';
import LeagueResults from '../components/league/LeagueResults';
import { useData } from '../DataProvider';
import Event from '../types/Event';
import { IsEventSignupOpen } from '../utils/utils';

//import NotVerifiedMsg from "../components/NotVerifiedMsg";

export default function Home(): ReactElement {
  const navigate = useNavigate();

  const { isRevealingGodsSecrets, isLoggedIn, isLoggingIn } = useAuth();
  const { adminOrgs, events, userProfile } = useData();

  useEffect(() => {
    if (!isLoggingIn) {
      if (isLoggedIn) {
        //!isAuthenticated && navigate("/verify", { state: {isEmailSentFirst: false }});
      } else {
        navigate('/welcome');
      }
    }
  }, [isLoggingIn, isLoggedIn, navigate]);

  function checkIsEventOpen(event: Event): boolean {
    return IsEventSignupOpen(event.signupOpensAt, event.signupClosesAt) && (!event.isTest || isRevealingGodsSecrets);
  }

  function OpenForSignupSection(): ReactElement {
    if (events) {
      const openEvents = events.filter(checkIsEventOpen);
      if (openEvents?.length > 0) {
        return (
          <Grid container direction="row" spacing={1}>
            {openEvents.flatMap((event: Event) => {
              return (
                <Grid key={'open' + event.itemId} minWidth={300} size={{ xs: 12, sm: 8, md: 6 }}>
                  <EventCard  variant="outlined" event={event} />
                </Grid>
              );
            })}
          </Grid>
        );
      } else {
        return <Typography variant="body2">Sadly, no events open right now.</Typography>;
      }
    } else {
      return <> </>;
    }
  }

  /* TODO: Rewrite without duplicate code */
  if (userProfile) {
    return (
      <Stack spacing={2} sx={{display: "flex", flexGrow: 1, p: 1}}>
        <Box sx={{pt: 1}}>
          <Typography variant="headline">Open for Signup</Typography>
          <OpenForSignupSection />
        </Box>
        <Divider variant="fullWidth" />
        <Stack spacing={1} sx={{display: "flex", flexGrow: 0, justifyContent: "flex-start", alignItems: "flex-start"}}>
          <Typography variant="headline">Eclectic Results</Typography>
          <LeagueResults leagueId="2BVRFRpII6n2kkzKfwoi" orgId="zRQde8GJXIflkGlRSwpU" memberId={userProfile.itemId}/>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            size="small"
            to="/league/2BVRFRpII6n2kkzKfwoi"
          >
            View all
          </Button>
        </Stack>
        <Divider variant="fullWidth" />
        <Box>
          <Typography variant="headline">Upcoming Events</Typography>
          <EventSchedule isUpcoming={true} />
        </Box>
        {adminOrgs.length > 0 && (
          <Fab
            variant="extended"
            color="primary"
            aria-label="add event"
            component={Link}
            to="/event-create"
          >
            <AddIcon />
            Add event
          </Fab>
        )}
      </Stack>
    );
  } else if (isLoggedIn) {
    return <Hourglass />;
  } else {
    return <Container />;
  }
}
