import { ReactElement, SyntheticEvent, useEffect, useState } from 'react';

import { getDownloadURL, ref, uploadBytes, UploadResult } from 'firebase/storage';

import { Link, useNavigate } from 'react-router-dom';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import BackIcon from '@mui/icons-material/ArrowBack';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import SaveIcon from '@mui/icons-material/Save';

import { MuiFileInput } from 'mui-file-input';

import { useAuth } from '../../AuthProvider';
import { useData } from '../../DataProvider';
import League from '../../types/League';
import { CompetitiveLevels } from '../../utils/Compete';
import Seasons from '../season/Seasons';
import GratiImageUpload from '../common/GratiImageUpload';

export default function LeagueEditor(props: { league: League; isNewLeague: boolean }): ReactElement {
  const { imagesRef, isRevealingGodsSecrets } = useAuth();
  const { addLeague, deleteLeague, isLeagueAdmin, isOrgAdmin, updateLeague } = useData();
  const navigate = useNavigate();

  const [competitiveHover, setCompetitiveHover] = useState(-1);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [league, setLeague] = useState<League>(props.league);
  const [leagueLogoUrl, setLeagueLogoUrl] = useState('');
  const [uploadLogo, setUploadLogo] = useState<File | null>(null);

  useEffect(() => {
    if (league && league.logo) {
      getDownloadURL(ref(imagesRef, league.logo))
        .then((url) => {
          setLeagueLogoUrl(url);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [imagesRef, league, league?.logo]);

  const deleteThisLeague = (id: string) => {
    console.log(`Deleting league ${id}`);
    deleteLeague(id);
    setDeleteConfirm(false);
    navigate('/leagues');
  };

  const uploadFile = function (newLogo: File) {
    const logoName = newLogo.name;
    const logoRef = ref(imagesRef, logoName);
    uploadBytes(logoRef, newLogo).then((/* snapshot */) => {
      if (league) {
        setLeague({ ...league, logo: logoName });
        console.log('Uploaded a blob or file!');
      }
    });
  };

  const handleImageUploaded = (snapshot: UploadResult) => {
    if (league) {
      setLeague({ ...league, logo: snapshot.ref.name });
    }
    console.log('Uploaded a blob or file!');
  };

  const handleSubmit = () => {
    if (league) {
      setLeague(league);
      if (props.isNewLeague) {
        addLeague(league);
      } else {
        updateLeague(league);
      }
      navigate('/leagues');
    }
  };

  const handleImageChange = (newFile: File | null) => {
    if (newFile) {
      setUploadLogo(newFile);
      uploadFile(newFile);
    }
  };

  if (league) {
    if (isOrgAdmin(league.orgId) || isLeagueAdmin(league.itemId)) {
      return ( 
        <Box
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Stack spacing={2}>
            <Typography variant="headline">
              {props.isNewLeague ? 'Create League' : 'Edit League'}
            </Typography>
            <TextField
              id="name"
              label="League Name"
              aria-label="League Name"
              aria-required="true"
              defaultValue={league.name}
              onChange={({ target }) => setLeague({ ...league, name: target.value })}
            />
            <GratiImageUpload
              onImageUploaded={handleImageUploaded}
              storageImageFileName={league.itemId + '-logo'}
              label="Logo"
            />
            <Stack direction="row">
              <Typography variant="body1" sx={{pr: 2}}>Competition </Typography>
              <Box
                sx={{
                  width: 200,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Rating
                  name="hover-feedback"
                  aria-label="Competitive Level"
                  value={league.competitionLevel}
                  precision={1}
                  icon={<EmojiEventsIcon fontSize="inherit" />}
                  getLabelText={(value: number) => `$Competitive Level ${value}`}
                  onChange={(_event: SyntheticEvent, newValue: number | null) => {
                    setLeague({ ...league, competitionLevel: newValue ? newValue : 0 });
                  }}
                  onChangeActive={(_event: SyntheticEvent, competitiveHover: number) => {
                    setCompetitiveHover(competitiveHover);
                  }}
                  emptyIcon={<EmojiEventsIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                />
                {league.competitionLevel !== null && (
                  <Box sx={{ ml: "1rem" }}>
                    {CompetitiveLevels[competitiveHover !== -1 ? competitiveHover : league.competitionLevel]}
                  </Box>
                )}
              </Box>
            </Stack>
          </Stack>
          <Seasons league={league} />
          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2} mt={8}>
            <Button size="medium" variant="contained" color="primary" startIcon={<SaveIcon />} type="submit">
              Save
            </Button>
            <Button
              size="medium"
              variant="outlined"
              color="secondary"
              startIcon={<CancelIcon />}
              component={Link}
              to="/leagues"
            >
              Cancel
            </Button>
            {isOrgAdmin(league.orgId) && (
              <>
                <Button
                  size="medium"
                  variant="text"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={() => setDeleteConfirm(true)}
                >
                  Delete
                </Button>
                {deleteConfirm && (
                  <span>
                    <Button size="large" onClick={() => deleteThisLeague(league.itemId)}>
                      Confirm
                    </Button>
                    <Button size="large" onClick={() => setDeleteConfirm(false)}>
                      Cancel
                    </Button>
                  </span>
                )}
              </>
            )}
          </Stack>
          {isRevealingGodsSecrets && league && (
            <FormControl component="fieldset">
              <FormControlLabel
                value="top"
                aria-label="Is a test event"
                aria-required="false"
                control={
                  <Checkbox
                    checked={league?.isTest}
                    name="Test League"
                    onChange={({ target }) => setLeague({ ...league, isTest: target.checked })}
                  />
                }
                label="Test?"
                labelPlacement="top"
              />
            </FormControl>
          )}
        </Box>
      );
    } else {
      return (
        <Stack>
          <Avatar
            alt="brand logo"
            id={'leagueLogo'}
            src={leagueLogoUrl ? leagueLogoUrl : ''}
            sx={{ width: 100, height: 100 }}
          />
          <Typography variant="headline">{league.name}</Typography>
          <Button
            size="medium"
            variant="contained"
            color="secondary"
            startIcon={<BackIcon />}
            component={Link}
            to="/leagues"
          >
            Back
          </Button>
        </Stack>
      );
    }
  } else {
    return <></>;
  }
}
