import { ReactElement, useEffect, useState } from 'react';

import { doc } from 'firebase/firestore';

import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

import { useDocumentData } from 'react-firebase-hooks/firestore';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';

import EditIcon from '@mui/icons-material/Edit';

import { useApp } from '../AppProvider';
import Hourglass from '../components/common/Hourglass';
import EventAttendees from '../components/event/EventAttendees';
import EventCard from '../components/event/EventCard';
import EventEditor from '../components/event/EventEditor';
import EventRegEditor from '../components/event/EventRegEditor';
import EventTeeSheet from '../components/event/EventTeeSheet';
import { useData } from '../DataProvider';
import Event from '../types/Event';
import { DisplayDate, DisplayDates } from '../utils/utils';
import { IsEventUpcoming } from '../utils/utils';

interface EventPageProps {
  isEditMode?: boolean;
}

export default function EventPage(props: EventPageProps): ReactElement {
  const { setPageName, setActions } = useApp();
  const { eventsCol, isLeagueAdmin, isOrgAdmin, updateEvent,  } = useData();
  const { eventId } = useParams();
  const navigate = useNavigate();

  const [isEditMode, setIsEditMode] = useState(props.isEditMode ? props.isEditMode : false);

  const [event, isEventLoading, eventLoadingError] = useDocumentData<Event>(doc(eventsCol, eventId));

  useEffect(() => {
    if (!isEventLoading) {
      if (eventLoadingError) {
        console.log('Event not found');
      }
      if (event) {
        const dateText = event.datetimes
          ? DisplayDates(
              event.datetimes.map((datetime) => datetime.toDate()),
              event.timezone
            )
          : DisplayDate(event.datetime.toDate(), event.timezone, 'M/D');

        setPageName(dateText + ' ' + event.name);
        if (!isEditMode && (isOrgAdmin(event.orgId) || isLeagueAdmin(event.leagueId))) {
          setActions([
            {
              name: 'Edit',
              handleClick: handleEditClick,
              icon: EditIcon,
            },
          ]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, isEventLoading, eventLoadingError, isEditMode, setActions, setPageName, isOrgAdmin, isLeagueAdmin]);

  /*
  const currentSeason = ():Season | null => {
    if (seasons && event && event.leagueId) {
      const currentTime = event.days[0].datetime;
      const season = 
        seasons.find(season => (season.leagueId == event.leagueId) && currentTime > season.startTime && currentTime <= season.endTime);
      return (season ? season : null);
    } else {
      return null;
    }
  }
  */

  const handleSubmit = (event: Event) => {
    if (event) {
      updateEvent(event.itemId, event);
    }
    navigate(-1);
  };

  const handleCancel = () => {
    navigate('/event/' + eventId, { replace: true });
    setIsEditMode(false);
  };

  const handleEditClick = () => {
    navigate('/event-edit/' + eventId, { replace: true });
    setIsEditMode(true);
  };

  const handleCreateTeeSheet = () => {
    if (event) {
      try {
        updateEvent(event.itemId, { isTeeSheetCreated: true });
      } catch (error) {
        console.log('Error setting isTeeSheetCreated to true: ' + error);
      }
    } else {
      console.log('Error setting isTeeSheetCreated to true: event is null');
    }
  };

  const Attendees = (): ReactElement => {
    if (event) {
      if (event.isGolf && event.isTeeSheetCreated) {
        return <EventTeeSheet eventId={event.itemId} orgId={event.orgId} />;
      } else {
        return (
          <Stack spacing={2} sx={{display: "flex", alignItems: "flex-start", my: 2}}>
            <EventAttendees event={event} isEditable={isOrgAdmin(event.orgId) || isLeagueAdmin(event.leagueId)} />
            {(isOrgAdmin(event.orgId) || isLeagueAdmin(event.leagueId)) && event.isGolf && (
              <Button
                type="button"
                key="formEdit"
                size="medium"
                variant="contained"
                color="secondary"
                onClick={() => handleCreateTeeSheet()}
              >
                Create Tee Sheet
              </Button>
            )}
          </Stack>
        );
      }
    } else {
      return <></>;
    }
  };

  const EventDetails = (): ReactElement => {
    if (event) {
      return (
        <Grid container spacing={2} sx={{justifyContent: "flex-start", backgroundColor: "surface.main"}}>
          <Grid size={{ xs: 12, sm: 6 }}>
            <EventCard event={event} variant="none"/>
              {event.isGolf && IsEventUpcoming(event.datetime) && !event.isTeeSheetCreated && (
                <EventRegEditor
                  eventId={event.itemId}
                  orgId={event.orgId}
                  isMemberMember={event.isMemberMember ? true : false}
                  isMemberGuest={event.isMemberGuest ? true : false}
                />
              )}
          </Grid>
          <Grid container size={{ xs: 12, sm: 6 }} sx={{pl: 1}}>
            <Stack direction="column" sx={{ width: 1 }}>
              <Divider variant="fullWidth" sx={{ display: { sm: 'none' } }} />
              <Attendees />
            </Stack>
          </Grid>
        </Grid>
      );
    } else {
      return <></>;
    }
  };

  if (event && !isEventLoading) {
    if (isOrgAdmin(event.orgId) || isLeagueAdmin(event.leagueId)) {
      if (isEditMode) {
        return <EventEditor event={event} isNewEvent={false} onCancel={handleCancel} onSubmit={handleSubmit} />;
      } else {
        return <EventDetails />;
      }
    } else {
      return <EventDetails />;
    }
  } else {
    return <Hourglass />;
  }
}
// && IsEventSignupOpen(event.signupOpensAt, event.signupClosesAt) &&
// {event.isGolf && IsEventUpcoming(event.datetime) && IsEventSignupClosed(event.signupClosesAt) &&
