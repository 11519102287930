import { ReactElement, useEffect, useState } from 'react';

import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';

import { Link, useNavigate } from 'react-router-dom';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import BackIcon from '@mui/icons-material/ArrowBack';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';

import { MuiFileInput } from 'mui-file-input';

import { useAuth } from '../../AuthProvider';
import { useData } from '../../DataProvider';
import Course from '../../types/Course';
import GooglePlace from '../../types/GooglePlace';
import GooglePlaceField from '../common/GooglePlaceField';

export default function CourseEditor(props: { course: Course; isNewCourse: boolean }): ReactElement {
  const { imagesRef, isGod, isRevealingGodsSecrets } = useAuth();
  const { addCourse, deleteCourse, updateCourse } = useData();
  const navigate = useNavigate();
  const [course, setCourse] = useState<Course>(props.course);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [courseLogoUrl, setCourseLogoUrl] = useState('');
  const [uploadLogo, setUploadLogo] = useState<File | null>(null);

  useEffect(() => {
    if (course && course.logo) {
      getDownloadURL(ref(imagesRef, course.logo))
        .then((url) => {
          setCourseLogoUrl(url);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [imagesRef, course, course?.logo]);

  const handleSubmit = () => {
    if (course) {
      if (props.isNewCourse) {
        addCourse(course);
      } else {
        updateCourse(course);
      }
      navigate('/courses');
    }
  };

  const handleImageChange = (newFile: File | null) => {
    if (newFile) {
      setUploadLogo(newFile);
      uploadFile(newFile);
    }
  };

  const handlePlaceChange = (place: GooglePlace) => {
    console.log(place);
    const name = place.structured_formatting.main_text;
    setCourse({
      ...course,
      googlePlaceId: place.place_id,
      name: name,
    });
    console.log(place);
  };

  const uploadFile = function (newLogo: File) {
    const logoName = newLogo.name;
    const logoRef = ref(imagesRef, logoName);
    uploadBytes(logoRef, newLogo).then((/* snapshot */) => {
      if (course) {
        setCourse({ ...course, logo: logoName });
        console.log('Uploaded a blob or file!');
      }
    });
  };

  const deleteThisCourse = (id: string) => {
    console.log(`Deleting course ${id}`);
    deleteCourse(id);
    setDeleteConfirm(false);
    navigate('/courses');
  };

  if (course) {
    if (isGod) {
      return (
        <Container disableGutters className="SectionContainer">
          <Box
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Stack spacing={8}>
              <GooglePlaceField
                placeUpdatedCallback={handlePlaceChange}
                placeId={course.googlePlaceId}
                placeName={course.name}
              />
              <TextField
                id="shortName"
                label="Short Name"
                aria-label="Abbreviated course name"
                aria-required="true"
                defaultValue={course.shortName}
                onChange={({ target }) =>
                  setCourse({
                    ...course,
                    shortName: target.value,
                  })
                }
              />

              <Grid container>
                <Grid container size={{ xs: 2 }} justifyContent="center">
                  <Avatar alt="brand logo" id={'courseLogo'} src={courseLogoUrl ? courseLogoUrl : ''} />
                </Grid>
                <Grid size={{ xs: 10 }}>
                  <MuiFileInput
                    fullWidth
                    label="Course Logo"
                    aria-label="course logo"
                    arai-required="false"
                    value={uploadLogo}
                    onChange={handleImageChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid size={{ xs: 3 }}>
                  <TextField
                    id="teeTimeSpacing"
                    label="Tee Time Interval"
                    aria-label="Time between tee times."
                    aria-required="true"
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    defaultValue={course.teeTimeSpacing}
                    onChange={({ target }) =>
                      setCourse({
                        ...course,
                        teeTimeSpacing: parseInt(target.value, 10),
                      })
                    }
                  />
                </Grid>
                <Grid size={{ xs: 3 }}>
                  <TextField
                    id="par"
                    label="Par"
                    aria-label="Course Par"
                    aria-required="false"
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    defaultValue={course.par}
                    onChange={({ target }) =>
                      setCourse({
                        ...course,
                        par: parseInt(target.value, 10),
                      })
                    }
                  />
                </Grid>
              </Grid>
              {isRevealingGodsSecrets && course && (
                <Grid size={{ xs: 4 }}>
                  <FormControl component="fieldset">
                    <FormControlLabel
                      value="top"
                      aria-label="Is a test event"
                      aria-required="false"
                      control={
                        <Checkbox
                          checked={course?.isTest}
                          name="Test Course"
                          onChange={({ target }) => setCourse({ ...course, isTest: target.checked })}
                        />
                      }
                      label="Test?"
                      labelPlacement="top"
                    />
                  </FormControl>
                </Grid>
              )}
              <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                <Button size="medium" variant="contained" color="primary" startIcon={<SaveIcon />} type="submit">
                  Save
                </Button>
                <Button
                  size="medium"
                  variant="outlined"
                  color="secondary"
                  startIcon={<CancelIcon />}
                  component={Link}
                  to="/courses"
                >
                  Cancel
                </Button>
                <Button
                  size="medium"
                  variant="text"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={() => setDeleteConfirm(true)}
                >
                  Delete
                </Button>
                {deleteConfirm && (
                  <span>
                    <Button size="large" onClick={() => deleteThisCourse(course.itemId)}>
                      Confirm
                    </Button>
                    <Button size="large" onClick={() => setDeleteConfirm(false)}>
                      Cancel
                    </Button>
                  </span>
                )}
              </Stack>
            </Stack>
          </Box>
        </Container>
      );
    } else {
      return (
        <Stack>
          <Grid container>
            <Grid size={{ xs: 12 }} alignItems="center" justifyContent="center">
              <Avatar
                alt="brand logo"
                id={'courseLogo'}
                src={courseLogoUrl ? courseLogoUrl : ''}
                sx={{ width: 100, height: 100 }}
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Typography variant="headline">{course.name}</Typography>
            </Grid>
            <Grid size={{ xs: 4 }}>
              <Typography variant="body2">{`Par: ${course.par}`}</Typography>
            </Grid>
            <Grid size={{ xs: 4 }}>
              <Typography variant="body2">{`Tee Time Interval: ${course.teeTimeSpacing}`}</Typography>
            </Grid>
          </Grid>
          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
            <Button
              size="medium"
              variant="contained"
              color="secondary"
              startIcon={<BackIcon />}
              component={Link}
              to="/courses"
            >
              Back
            </Button>
          </Stack>
        </Stack>
      );
    }
  } else {
    return <Container />;
  }
}
