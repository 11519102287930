import { 
  ReactElement, 
  useEffect 
} from "react";

import { doc } from "firebase/firestore";

import { useParams } from "react-router-dom";

import { useDocumentData } from 'react-firebase-hooks/firestore';

import Box from "@mui/material/Box";

import { useApp } from "../AppProvider";
import Hourglass from "../components/common/Hourglass";
import CourseEditor from "../components/course/CourseEditor";
import { useData } from "../DataProvider";
import Course from "../types/Course";

interface CourseProps {
  edit?: boolean;
}
export default function CoursePage(props: CourseProps): ReactElement {
  const { setPageName } = useApp();

  const {
    coursesCol,
  } = useData();

  const { courseId } = useParams();

  const [course, isCourseLoading, courseLoadingError] = useDocumentData<Course>(doc(coursesCol, courseId));

  useEffect(() => {
    if (!isCourseLoading && (courseLoadingError || !course)) {
      console.log("Course not found");
    } else {
      if (course) {
        setPageName(course.name);
        console.log(course);
      }
    }
  }, [course, isCourseLoading, courseLoadingError, setPageName]);

  const Holes = (): ReactElement => {
    if (course) {
      return (<Box></Box>);
    } else {
      return (<Box></Box>);
    }
  };

  if (course) {
    return (
      <Box>
        <CourseEditor course={course} isNewCourse={false} />
        <Box>
          <Holes />
        </Box>
      </Box>
    );
  } else {
    return (<Hourglass />);
  }
};
// && IsEventSignupOpen(event.signupOpensAt, event.signupClosesAt) && 
// {event.isGolf && IsEventUpcoming(event.datetime) && IsEventSignupClosed(event.signupClosesAt) &&