import { ReactElement } from 'react';

import { orderBy, query } from 'firebase/firestore';

import { useCollectionData } from 'react-firebase-hooks/firestore';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

import { useAuth } from '../../AuthProvider';
import { useData } from '../../DataProvider';
import Course from '../../types/Course';

import { CourseRow } from './CourseRow';

export const CourseDirectory = (): ReactElement => {
  const { isRevealingGodsSecrets } = useAuth();
  const { coursesCol } = useData();

  const q = query(coursesCol, orderBy('name'));

  const [courses, isLoading, isError] = useCollectionData<Course>(q);

  if (isLoading) {
    return <Box width="100vw" />;
  } else if (isError) {
    return <Box width="100vw" />;
  } else if (courses) {
    return (
      <Container disableGutters className="SectionContainer">
        <Grid container sx={{ width: '100vw' }}>
          <Grid size={{ xs: 12 }}>
            <Typography variant="headline">Golf Courses</Typography>
          </Grid>
          <Grid size={{ xs: 12 }}>
            {courses.flatMap((course: Course) => {
              if (isRevealingGodsSecrets || !course?.isTest) {
                return <CourseRow key={course.itemId} course={course} />;
              } else {
                return null;
              }
            })}
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return <Container />;
  }
};
