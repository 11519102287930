import { 
  ReactElement, 
  useEffect, 
  useState 
} from "react";

import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

import { useCreateUserWithEmailAndPassword, useUpdateProfile } from "react-firebase-hooks/auth";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";

import GolfCourseIcon from '@mui/icons-material/GolfCourse';
// import { sendEmailVerification } from "firebase/auth";

import { useAuth } from "../AuthProvider";
import { useData } from "../DataProvider"; 
import { validateEmail } from "../utils/validators";

const inputProps = {
  minLength: 6
}

export default function Signup(): ReactElement {
  const navigate = useNavigate();
  const [displayName, setDisplayName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isEmailEdited, setIsEmailEdited] = useState<boolean>(false); 
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const [isEmailInUse, setIsEmailInUse] = useState<boolean>(false);

  const { auth, isLoggedIn } = useAuth(); 
  const { updateUserProfile, userProfile } = useData();
  const [ createUserWithEmailAndPassword, newUser, isCreating, createError, ] = useCreateUserWithEmailAndPassword(auth);
  const [ updateNewUser, isUpdating, updateNameError ] = useUpdateProfile(auth);

  useEffect(() => {
    if (newUser && !isUpdating) {
      console.log("user created");
      try {
        updateNewUser({displayName: displayName}).then(() => {
          updateUserProfile(newUser.user.uid, { name: displayName });
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [newUser, displayName, isUpdating, updateNewUser, updateUserProfile]);

  useEffect(() => {
    if (updateNameError) {
      console.log("unable to update user name");
    }
  }, [updateNameError])

  useEffect(() => {
    if (createError) {
      switch (createError?.code) {
        case "auth/email-already-in-use":
          setIsEmailInUse(true);
          setIsEmailValid(false);
          console.log("email already in use");
          break;
        case "auth/invalid-email":
          console.log("invalid email");
          break;
        case "auth/weak-password":
          console.log("weak password");
          break;
        default:
          console.log(createError);
          break;
      }
    }
  }, [createError]);

  const handleSubmit = () => {
    if (!isCreating) {
      createUserWithEmailAndPassword(email, password);
    }
  }

  const handleEmailChange = (val: string) => {
    setIsEmailInUse(false); 
    if (val === "") {
      setIsEmailEdited(false);
    } else {
      setIsEmailValid(validateEmail(val));  
    }
    setEmail(val);
  }

  if (userProfile) {
    navigate("/");
  } else {
    return (
      <Container>
        <Box
          component="form" 
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}          
        >
          <Stack spacing={2} sx={{display: 'flex' , flexDirection: 'column', alignItems: 'center'}}>
            <TextField
              fullWidth
              required
              autoFocus={true}
              id="displayName"
              label="Name"                
              aria-label="name"
              helperText="First and last name"
              defaultValue={displayName}
              onChange={({ target }) => setDisplayName(target.value)}
            />
  
            <TextField
              fullWidth
              required
              error={isEmailEdited && isEmailValid === false}                                        

              id="email"
              label={isEmailEdited && isEmailValid === false ? "Error" : "Email"}
              onBlur={(e) => { e.target.value.length > 0 && setIsEmailEdited(true)} }
              aria-label="Email"
              helperText={isEmailEdited && isEmailValid === false ? (isEmailInUse ? "Email already in use." : "Invalid email address") : "Valid email address" }
              defaultValue={email}
              onChange={(e) => handleEmailChange(e.target.value)}
            />
  
            <TextField
              fullWidth
              required
              id="password"
              type="password"
              label="Password"
              aria-label="Password"                
              helperText="Don't make it obvious!"
              defaultValue={password}
              inputProps={inputProps}
              onChange={({ target }) => setPassword(target.value)}
            />
            { isCreating ?
              <Box>
                <Typography>Creating your profile...</Typography>
              </Box>
              :
              <Button type="submit" variant="contained" startIcon={<GolfCourseIcon/>}>
                Register
              </Button> 
            }  
          </Stack>
        </Box>
        {!isLoggedIn &&
          <Stack direction="row" spacing={2} sx={{display: 'flex' , flexDirection: 'row', alignItems: 'center'}}>
            <Typography variant="body2" color="surface.contrastText" align="center">
            Already a member? 
            </Typography>
            <Button variant="contained" color="secondary" component={Link} to="/login">
              Login
            </Button>
          </Stack>
        }
      </Container>);
  }
  return(<Container />);
}
