import { 
  ReactElement,
  useEffect
} from "react";

import { useNavigate } from "react-router";

import Box from "@mui/material/Box";

import { useApp } from "../AppProvider";
import LeagueEditor from "../components/league/LeagueEditor";
import { useData } from "../DataProvider";
import League from "../types/League";
import { CurrentDateTime } from "../utils/utils";

export default function LeagueCreatePage(): ReactElement {
  const { setPageName } = useApp();
  const { adminOrgs, isOrgAdmin, orgs } = useData();

  const navigate = useNavigate();

  const createNewLeague = (orgId: string): League => {
    const newLeague: League = { 
      name: "",
      competitionLevel: 0,
      creatorId: "",
      createTime: CurrentDateTime(),
      description: "",
      isTest: false,
      itemId: "",
      logo: "",
      orgName: orgs ? orgs.find(org => org.itemId === orgId)?.name || "" : "",
      orgId: orgId,
    };
    return newLeague;
  };

  useEffect (() => {
    setPageName("Create League");
  }, [setPageName]);

  if (adminOrgs.length > 0 && isOrgAdmin(adminOrgs[0])) {
    const league = createNewLeague(adminOrgs[0]);
    return (
      <Box>
        <LeagueEditor league={league} isNewLeague={true} />
      </Box>
    );
  } else {
    navigate("/");
    return <></>;
  }
};
// && IsEventSignupOpen(event.signupOpensAt, event.signupClosesAt) && 
// {event.isGolf && IsEventUpcoming(event.datetime) && IsEventSignupClosed(event.signupClosesAt) &&