import { forwardRef, ReactElement, useEffect, useState } from 'react';

import { doc } from 'firebase/firestore';

import { Link, LinkProps, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';

import { useDocumentData } from 'react-firebase-hooks/firestore';

import Box from "@mui/material/Box";
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

import { useApp } from '../AppProvider';
import { useAuth } from '../AuthProvider';
import Hourglass from '../components/common/Hourglass';
import LeagueCard from '../components/league/LeagueCard';
import LeagueEditor from '../components/league/LeagueEditor';
import LeagueMembers from '../components/league/LeagueMembers';
import LeagueResults from '../components/league/LeagueResults';
import SeasonStandingCsvUpload from '../components/season/SeasonStandingCsvUpload';
import { useData } from '../DataProvider';
import League from '../types/League';
import { CurrentDateTime } from '../utils/utils';

interface LeaguePageProps {
  isEditMode?: boolean;
}

export default function LeaguePage(props: LeaguePageProps): ReactElement {
  const { setActions, setPageName } = useApp();
  const { isLeagueAdmin, isOrgAdmin } = useData();
  const { isGod } = useAuth();
  const { leaguesCol } = useData();
  const { leagueId } = useParams();
  const navigate = useNavigate();

  const [isEditMode, setIsEditMode] = useState(props.isEditMode ? props.isEditMode : false);

  const [league, isLeagueLoading, leagueLoadingError] = useDocumentData<League>(doc(leaguesCol, leagueId));

  useEffect(() => {
    if (!isLeagueLoading && (leagueLoadingError || !league)) {
      console.log('League not found');
    }
    if (league) {
      setPageName(league.name);
      if (isLeagueAdmin(league.itemId) || isOrgAdmin(league.orgId)) {
        setActions([
          {
            name: 'Edit',
            handleClick: handleEditClick,
            icon: EditIcon,
          },
        ]);
      }
    }
  }, [league, isLeagueLoading, leagueLoadingError, isLeagueAdmin, isOrgAdmin, setActions, setPageName]);

  const handleEditClick = () => {
    if (league) {
      navigate('/league-edit/' + league.itemId, { replace: true });
    }
    setIsEditMode(true);
  };

  const currentSeason = () => {
    const currentTime = CurrentDateTime();

    if (league && league.season && league.season.length > 0) {
      const currentSeason = league.season.find(
        (season) => currentTime > season.startTime && currentTime <= season.endTime
      );
      return currentSeason ? currentSeason.itemId : '';
    } else {
      return '';
    }
  };

  const LeagueDetails = (): ReactElement => {
    if (league) {
      return (
        <Stack spacing={2}>
          <LeagueCard league={league} variant="none"/>
          <Divider sx={{ display: { sm: 'none' } }} />
          <Box>
            <Typography variant="headline">Results</Typography>
          </Box>
          <LeagueResults leagueId={league.itemId} orgId={league.orgId} />
          {isGod && <SeasonStandingCsvUpload />}
          <Box>
            <Typography variant="title">Members</Typography>
          </Box>
          <LeagueMembers league={league} isEditable={true} />
        </Stack>
      );
    } else {
      return <></>;
    }
  };

  if (league) {
    if (isOrgAdmin(league.orgId) || isLeagueAdmin(league.itemId)) {
      const FabLinkBehavior = forwardRef<any, Omit<LinkProps, 'to'>>((props, ref) => (
        <Link
          ref={ref}
          to="/event-create"
          state={{ leagueId: league.itemId, seasonId: currentSeason() }}
          {...props}
          role={undefined}
        />
      ));
      return (
        <Container disableGutters className="SectionContainer">
          {isEditMode ? <LeagueEditor league={league} isNewLeague={false} /> : <LeagueDetails />}
          <Fab variant="extended" color="primary" aria-label="add event" component={FabLinkBehavior}>
            <AddIcon />
            Add event
          </Fab>
        </Container>
      );
    } else {
      return <LeagueDetails />;
    }
  } else {
    return <Hourglass />;
  }
}
// && IsEventSignupOpen(event.signupOpensAt, event.signupClosesAt) &&
// {event.isGolf && IsEventUpcoming(event.datetime) && IsEventSignupClosed(event.signupClosesAt) &&
