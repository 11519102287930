

export const loadPlacesApi = (isNewApi = false) => {
  const GOOGLE_PLACES_API_KEY = isNewApi ? "AIzaSyAEDNjXUP3iVPvgWVfHjxMFO8ZyZ8UhQh0" : "AIzaSyBwpVS6n-nvwK7neQ0z-pSa7o0l_Q4AdMI";
  const placesURL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_PLACES_API_KEY}&loading=async&libraries=places`;

  const scripts = document.getElementsByTagName('script');

  for (let i = 0; i < scripts.length; i++) {
    if (scripts[i].src.indexOf(placesURL) === 0) {
      return scripts[i];
    }
  }

  const googleMapScript = document.createElement('script');
  googleMapScript.src = placesURL;
  console.log(googleMapScript);
  window.document.body.appendChild(googleMapScript);

  return googleMapScript;
};