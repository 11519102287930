import { ReactElement } from "react";

import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';

export const Constitution = (): ReactElement => {
  return (
<Box>
  <Typography variant="subtitle1">
    ARTICLE I     NAME
  </Typography>
  <Typography variant="body1">
    This organization will be known as “Sunriver Women’s Golf Association” (SWGA), 
    a non-profit organization, functioning as a member of the Oregon Golf Association. 
    This constitution will govern the Sunriver women’s golf group.
  </Typography>
  <Typography variant="subtitle1">
    ARTICLE II     PURPOSE
  </Typography>
  <Typography variant="body1">
    The purpose of this association is to conduct weekly golf activities during
    the golf season, determine a club champion, coordinate competition play with 
    local organizations (e.g. Team Play, Seniors, Visitations), foster and maintain 
    the spirit of good will and true sportsmanship, and encourage conformance to the 
    USGA Rules of Golf and accepted golfing etiquette. 
  </Typography>
  <Typography variant="subtitle1">
    ARTICLE III     ELIGIBILITY, DUES, AND MEETINGS
  </Typography>
  <ul>
    <li style={{listStyleType: "none"}}>Section 1: Membership
      <ol type = "A">
        <li>Any woman golfer may be eligible to join SWGA and must comply with the 
          rules and regulations of the association. A golfer with a current USGA 
          handicap index of 54 or less can  participate in weekly competitions if dues
          are current. A player with a course handicap of 41 through 54 may compete in 
          weekly competitions with an adjusted course handicap of no more than 40. To 
          compete in club tournaments, the handicap will be adjusted to 36.</li>
        <li>
          New members without a USGA handicap must first play three rounds at Sunriver Woodlands or Meadows course
          before they are eligible to compete in official SWGA competitions. These games should average no more than a 
          USGA handicap index of 54. The games must have been played within the past year. New members without a 
          handicap may play with the SWGA group during weekly play days, but to participate in tournaments and weekly 
          competitions a handicap must be established.  
        </li>
      </ol>
    </li>
    <li style={{listStyleType: "none"}}>Section 2: Dues
      <ol type = "A">
        <li>
          The amount of yearly dues will be set by the executive board
        </li>
        <li>
          Dues will be paid by the end of each year for the next year’s golf season, enabling members to be included in 
          the GHIN handicap system. Members can join SWGA after January 1, but will pay a higher rate.
        </li>
      </ol>
    </li>
    <li style={{listStyleType: "none"}}>Section 3: 
      <ol type="A">
        <li>
          There will be a minimum of two meetings per year. One meeting will be held in the spring to organize and 
          inform members of golf activities. The other meeting will be held in the fall to award prizes for the current 
          year and to elect and install officers for the following year.
        </li>
        <li>
          Interim meetings may be called any time during the year by the President. 
        </li>
      </ol>
    </li>
  </ul>
  <Typography variant="subtitle1">
    ARTICLE IV     ELECTIONS, OFFICERS, AND EXECUTIVE BOARD
  </Typography> 
  <ul>
    <li style={{listStyleType: "none"}}><b>Section 1 </b>
      Officers will be nominated by the outgoing board. Members may nominate themselves or another member by submitting 
      the name(s), in writing, to the board 15 days prior to the closing meeting. A slate of officers will be presented 
      to the membership 10 days prior to the fall meeting via email. Members will have 5 days to respond to the email 
      with their vote. A final tally of email votes will be presented at the closing meeting by the outgoing President. 
      Additionally, an email with the results will be sent to the membership. New officers will assume their duties 
      following election and installation.
    </li>
    <li style={{listStyleType: "none"}}><b>Section 2 </b>
      There will be four officers elected each year: President, Vice-President, Secretary, and Treasurer. These four 
      officers, in addition to the Past President, will make up the Executive Board. Duties of officers are detailed in 
      the current By-Laws.
    </li>
    <li style={{listStyleType: "none"}}><b>Section 3 </b>
      The full Board will consist of the four officers, the Past President, and the Chairperson of each of the 
      committees, and will be the governing board of the Association.
    </li>
    <li style={{listStyleType: "none"}}><b>Section 4 </b>
      Committee Chairpersons will be appointed by the President. Committees and duties thereof will be detailed in the 
      current By-Laws.
    </li>
  </ul>
  <Typography variant="subtitle1">
    ARTICLE V   WEEKLY COMPETITION, HANDICAPS, AND CLUB TOURNAMENTS
  </Typography> 
  <ul>
    <li style={{listStyleType: "none"}}><b>Section 1 Weekly Competition </b>
      <ol type = "A">
        <li>
          Weekly competition will be played on either the Sunriver Woodlands or Meadows course, as set up by the Weekly 
          Competition committee. A game fee will be charged prior to the weekly competition for everyone who chooses to 
          compete in the weekly competition. Play will begin in April and end in October.  
        </li>
        <li>
          A non-member is not eligible for any association competition, tournament, or visitation, with the exception of 
          tournaments specifically designated as those allowing guests. A guest player will also be welcome to play with 
          the SWGA during weekly play if space is available but cannot participate in the weekly competition.
        </li>
      </ol>
    </li>
    <li style={{listStyleType: "none"}}><b>Section 2 Handicaps </b>
      <p>
        Handicaps will be determined by the OGA, and all members’ scores at any course are to be posted according to 
        rules established by the OGA. For the SWGA Partnership Tournament, the Member/Guest Tournament, and the Club 
        Championship, a maximum handicap of 36 will be used. For all other competition play, a maximum handicap of 40 
        strokes will be used.  
      </p>
    </li>
    <li style={{listStyleType: "none"}}><b>Section 3 Tournaments </b>
      <ol type = "A">
        <li>
          Tournaments and seasonal competitions (Eclectic and Most Improved Player) are open to all SWGA members in good 
          standing who have made an obvious attempt to play in the weekly competitions and other club events on a 
          regular basis (travel and illness absences to be considered by five Board members on an individual basis). 
        </li>
        <li>
          Club Championship will be held annually, with the champion determined by rules set up by the Tournament 
          Committee with the approval of the executive board. The Club Championship will be a two-day event. 
        </li>
        <li>
          Member/Guest Tournament will be held annually starting in 2022. There will be no more than a 20-point spread 
          between partners. Teams will consist of one SWGA member and one non-member and will be a two-day event
        </li>
        <li>
          Partnership Tournament will be held annually. There will be no more than a 10-point handicap spread between 
          partners. Teams will consist of two SWGA members and will be a two-day event.
        </li>
        <li>
          Visitation Tournament will be hosted at Sunriver every other year. Visitation play is coordinated through the 
          Central Oregon Women’s Visitation group and will be played according to the rules governed by COWVG.
        </li>
        <li>
          Senior Women’s Tournament will be hosted annually at Sunriver. Senior women’s play is coordinated through the 
          Central Oregon Senior Women’s Golf Association and will be played according to the rules governed by COSWGA.
        </li>
        <li>
          Central Oregon Team Play will be hosted annually at Sunriver or Crosswater. Team Play is coordinated through 
          the Central Oregon Team Play association and will be played according to the rules governed by the Team Play 
          association.  
        </li>
      </ol>
    </li>
  </ul>
  <Typography variant="subtitle1">
    ARTICLE VI   CENTRAL OREGON TEAM PLAY 
  </Typography> 
  <ul>
    <li style={{listStyleType: "none"}}><b>Section 1 </b>
      Sunriver Women’s Golf Association will participate in Team Play in Central Oregon. 
    </li>
    <li style={{listStyleType: "none"}}><b>Section 2 </b>
      Any SWGA member who meets the eligibility requirements of the Central Oregon Team, has a handicap of 0-25, and actively participates in SWGA weekly competition, is eligible to play on Sunriver’s Team. 
    </li>
    <li style={{listStyleType: "none"}}><b>Section 3 </b>
      The Team Captain is responsible for organizing and fielding the team. 
      <ol type = "A">
        <li>
          Players must declare at the beginning of the season (before the first team play) the club they are going to represent. Each player should be responsible for posting all applicable scores from which her index is determined. 
        </li>
        <li>
          Players must compete in SWGA weekly play within 30 days of a team play event. Exceptions may be made for the first team event. Other exceptions for tournament conflicts and other unforeseen circumstances may be made at the discretion of the team captain.
        </li>
      </ol>
    </li>
  </ul>
  <Typography variant="subtitle1">
    ARTICLE VII   AMENDMENTS 
  </Typography> 
  <ul>
    <li style={{listStyleType: "none"}}><b>Section 1 </b>
    The Sunriver women’s golf group will establish their own By-Laws which will be updated and voted on as necessary by the executive committee.
    </li>
    <li style={{listStyleType: "none"}}><b>Section 2 </b>
    Dues will be <b>$65.00</b> per year if paid by December 1. After December 1, dues will be <b>$70.00</b>.
    </li>
    <li style={{listStyleType: "none"}}><b>Section 3 </b>
      The weekly competition fee will be <b>$5.00</b>.
    </li>
    <li style={{listStyleType: "none"}}><b>Section 4 </b>
      A hole-in-one kitty of <b>$2.00</b> per member will be included in the membership dues for all SWGA members. A hole in one may be made at any event listed on the SWGA golf events calendar. The event must be open to all SWGA members (no restrictions) and must be witnessed by at least one other person who must sign the scorecard. No mulligan or provisional ball will qualify for the hole in one award. The hole in one must be between the first play day in the spring and the fall meeting. Any monies not won during the current golf season will be transferred to the general fund to be designated at the discretion of the executive committee for the betterment of the club.  If more than one SWGA member gets a hole in one during the year, the monies will be divided between those players. The hole-in-one payout(s) will be awarded at the closing meeting.
    </li>
    <li style={{listStyleType: "none"}}><b>Section 5 </b>
      Any questions on playing rules will be decided by the Club Professional. Questions on SWGA rules will be decided by the Rules and Regulations Committee.
    </li>
    <li style={{listStyleType: "none"}}><b>Section 6 </b>
      Duties of Officers and Committee Chairpersons
      <ol type="A">
        <li>
          President: Will call and preside at meetings, appoint all committee chairpersons, act as ex-officio member on all committees, Co-Chair the Club Championship, and perform any other duties that may arise.
        </li>
        <li>
          Vice President: Perform the duties of the President in her absence. Co-Chair the Member/Guest Tournament, Co-Chair the Club Championship (with the President), and perform any other duties asked of her by the President. She will be expected to follow as President in the next term. 
        </li>
        <li>
        Secretary: Transcribe the minutes at meetings, take notes at executive board meetings, and take care of all correspondence. Maintain the official up-to-date copy of SWGA Constitution and By-Laws.
        </li>
        <li>
        Treasurer: Collect dues, pay OGA memberships, pay all bills, keep a complete roster of members, and maintain financial records. 
        </li>
        <li>
        Past-President: Remain involved in a consultative capacity to the President and the Executive Board for one year immediately following her term as President. Co-Chair the Home Visitation in Sunriver (held in alternate years). 
        </li>
        <li>
        Club Championship Chairperson: Responsible for running the SWGA Club Championship. 
        </li>
        <li>
        Historian: Collect pictures and other materials pertaining to SWGA at the request of the Board and keep the SWGA website up to date. 
        </li>
        <li>
        Hospitality Chairperson: Will oversee set up and coordinate food and beverages for the opening and closing meetings and coordinate any other social functions of the SWGA.
        </li>
        <li>
        Member/Guest Tournament Chairperson. Responsible for running the Member/Guest Tournament.
        </li>
        <li>
        Membership Chairperson: Welcomes and provides membership information to prospective members (application forms, etc.) and, with the help of the OGA Representative, determines their eligibility as established by the SWGA Constitution.  
        </li>
        <li>
        OGA Representative: Maintains handicap integrity of membership and is the OGA representative. 
        </li>
        <li>
        Partnership Tournament Chairperson (Member/Member): Responsible for running the Partnership Tournament.
        </li>
        <li>
        Publicity Chairperson: Report golf events and accomplishments to the news media (Scene, Channel 4, Bulletin, etc.).  
        </li>
        <li>
        Rules and Regulations Committee: The Chairperson will work with the Club Professional to decide on questions arising during weekly competition which concern SWGA Rules. The Chairperson assists members in understanding the rules and informs members of any pertinent changes to the rules.
        </li>   
        <li>
        Senior Women’s Golf Association Representative: Will be responsible for coordinating with the Central Oregon Senior Women’s Golf Association, monitoring membership and waiting lists, and organizing the senior women’s home tournament play.
        </li>  
        <li>
        Team Captain and Representative: Will be responsible for organizing a Sunriver Team for Central Oregon Team competition, and organizing the Team Play home tournament. 
        </li>  
        <li>
        Visitation (Away) Chairperson: Coordinate with the Central Oregon Women’s Visitation group, contact members, and turn in names of participants for Visitation tournaments.
        </li>  
        <li>
        Visitation (Home) Chairperson: Responsible for running the home Visitation tournament, held every other year at Sunriver. 
        </li>  
        <li>
        Weekly Competition Chairperson: Plan and govern 18-hole and 9-hole weekly competitions. 
        </li>  
        <li>
        Well-wishing: Send cards or flowers to members experiencing personal difficulty at the request of the Board.
        </li>                                                                                                                                                                                                       
      </ol>
    </li>
  </ul> 
  </Box>);
}
