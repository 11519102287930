import { ReactElement, useEffect, useState } from 'react';

import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';

import Competition from '../../types/Competition';

interface CompetitionEditorProps {
  onChange: (competitionChange: Partial<Competition>) => void;
  competition: Competition;
}

export default function CompetitionEditor(props: CompetitionEditorProps): ReactElement {
  const [competition, setCompetition] = useState<Competition>(props.competition);

  // Todo handle loading errors and waiting to load hourglass
  useEffect(() => {}, [competition]);

  const handleOnChange = (updatedCompetition: Partial<Competition>) => {
    setCompetition({ ...competition, ...updatedCompetition });
    props.onChange(updatedCompetition);
  };

  if (competition) {
    return (
      <Container disableGutters className="SectionContainer">
        <TextField
          id="name"
          label="Name"
          value={competition.name ? competition.name : ''}
          onChange={(e) => handleOnChange({ name: e.target.value })}
        />
      </Container>
    );
  } else {
    return <> </>;
  }
}
