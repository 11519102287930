import { ReactElement } from 'react';

import { NavLink } from 'react-router-dom';

import Container from '@mui/material/Container';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';

import LeagueCard from '../components/league/LeagueCard';
import { useData } from '../DataProvider';
import League from '../types/League';

export default function Leagues(): ReactElement {
  const { adminOrgs, isOrgAdmin, leagues } = useData();

  return (
    <Container disableGutters className="SectionContainer">
      {leagues && (
        <>
          <Typography variant="headline">Leagues</Typography>
          <Grid container direction="row" spacing={1}>
            {leagues &&
              leagues.flatMap((league: League) => {
                return (
                  <Grid
                    key={'open' + league.itemId}
                    minWidth={300}
                    size={{
                      xs: 12,
                      sm: 6,
                      md: 4,
                    }}
                  >
                    <LeagueCard league={league} />
                  </Grid>
                );
              })}
          </Grid>
        </>
      )}
      {adminOrgs.length > 0 && isOrgAdmin(adminOrgs[0]) && (
        <Fab
          variant="extended"
          color="primary"
          aria-label="add league"
          component={NavLink}
          to="/league-create"
        >
          <AddIcon />
          Add league
        </Fab>
      )}
    </Container>
  );
}
