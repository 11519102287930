import { ReactElement, useEffect } from 'react';

import { collection, orderBy, Query, query, where } from 'firebase/firestore';

import { useCollectionData } from 'react-firebase-hooks/firestore';

import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';

import { useData } from '../../DataProvider';
import { firestore } from '../../firebase';
import League from '../../types/League';
import Season from '../../types/Season';
import {} from '../../utils/Compete';
import { CurrentDate } from '../../utils/utils';

import SeasonEditor from './SeasonEditor';

interface SeasonsProps {
  league: League;
  onChange?: (value: Season[] | null) => void;
}

export default function Seasons(props: SeasonsProps): ReactElement {
  const league = props.league;

  const { addSeason, deleteSeason, updateSeason } = useData();

  const qSeason = query(
    collection(firestore, 'org/zRQde8GJXIflkGlRSwpU/season/'),
    where('leagueId', '==', league.itemId),
    orderBy('name')
  ) as Query<Season>;

  const [seasons, isSeasonsLoading, isSeasonsError] = useCollectionData<Season>(qSeason);

  useEffect(() => {
    if (isSeasonsLoading) {
      console.log('Loading seasons');
    } else if (seasons) {
      console.log('Loaded ' + seasons.length + ' seasons');
    } else if (isSeasonsError) {
      console.log('Error loading seasons: ' + isSeasonsError);
    }
  }, [seasons, isSeasonsError, isSeasonsLoading]);

  const handleSeasonAdd = () => {
    addSeason({
      endTime: CurrentDate(),
      leagueId: league.itemId,
      leagueName: league.name,
      orgId: league.orgId,
      orgName: league.orgName,
      name: '',
      startTime: CurrentDate(),
    });
  };

  const handleSeasonChange = (season: Season, reason: string) => {
    switch (reason) {
      case 'update':
        console.log('Update ', season.itemId);
        try {
          updateSeason(season);
        } catch (error) {
          console.error('Error updating season: ', error);
        }
        break;
      case 'delete':
        try {
          deleteSeason(season.itemId);
        } catch (error) {
          console.error('Error deleting season: ', error);
        }
        console.log('Delete ', season.itemId);
        break;
      default:
        console.error('Unknown reason: ', reason);
    }
  };

  if (!isSeasonsLoading) {
    return (
      <Stack spacing={2}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
          <Typography variant="headline">
            Seasons
          </Typography>
          <IconButton color="secondary" size="medium" onClick={() => handleSeasonAdd()}>
            <AddIcon />
          </IconButton>
        </Stack>
        {seasons &&
          seasons.map((season) => <SeasonEditor key={season.itemId} season={season} onChange={handleSeasonChange} />)}
      </Stack>
    );
  } else {
    return <></>;
  }
}