import { 
  ReactElement, 
  useEffect 
} from "react";

import { Timestamp } from "firebase/firestore";

import { 
  useLocation, 
  useNavigate 
} from "react-router";

import Box from "@mui/material/Box";

import { useApp } from "../AppProvider";
import EventEditor from "../components/event/EventEditor";
import { useData } from "../DataProvider";
import Event from "../types/Event";

export default function EventCreatePage(): ReactElement {
  const { state } = useLocation();
  const leagueId = state ? state.leagueId : "";
  const seasonId = state ? state.seasonId : "";
  console.log("League: ", leagueId);
  console.log("season: ", seasonId);

  const { setPageName } = useApp();
  const { addEvent, adminOrgs, isOrgAdmin, leagues, seasons } = useData();
  const navigate = useNavigate();

  useEffect (() => {
    setPageName("Create Event");
  }, [setPageName]);

  const handleSubmit = (event: Event) => {
    if (event) {
      addEvent(event);
    };
    navigate(-1);
  }

  const createNewEvent = (): Event => {
    const leagueName = (leagueId && leagues) ? leagues.find(league => league.itemId === leagueId)?.name : "";
    const seasonName = (seasonId && seasons) ? seasons.find(season => season.itemId === seasonId)?.name : "";
    const newEvent: Event = { 
      name: '', 
      courseId: '',
      creatorId: '',
      days: [{courseId: '', datetime: Timestamp.now(), duration: 60, isGolf: true, isShotgun: false}],
      datetime: Timestamp.now(),
      endTime: Timestamp.now(),
      description: '',
      isGolf: true,
      isShotgun: false,
      isTest: false,
      itemId: '',
      leagueId: leagueId,
      leagueName: leagueName ? leagueName : "",
      logo: '',
      maxPlayers: 0,
      numTeeTimes: 0,
      orgId: adminOrgs[0],
      seasonId: seasonId,
      seasonName: seasonName ? seasonName : "",
      signupOpensAt: Timestamp.now(),
      signupClosesAt: Timestamp.now(),
      socialLocation: "",
      timezone: "America/Los_Angeles"
    };
    return newEvent;
  };

  if (adminOrgs.length > 0 && isOrgAdmin(adminOrgs[0])) {
    const event = createNewEvent();

    return (
      <Box>
        <EventEditor 
          event={event} 
          isNewEvent={true} 
          onSubmit={handleSubmit}
        />
      </Box>
    );
  } else {
    navigate("/");
    return <></>;
  }
};
// && IsEventSignupOpen(event.signupOpensAt, event.signupClosesAt) && 
// {event.isGolf && IsEventUpcoming(event.datetime) && IsEventSignupClosed(event.signupClosesAt) &&