import { 
  useCallback,
  useEffect, 
  useRef,
  useState
} from "react";

import { 
  deleteObject, 
  getDownloadURL, 
  uploadBytes, 
  UploadResult 
} from "firebase/storage";
import { ref } from "firebase/storage";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import DeleteIcon from "@mui/icons-material/Delete";
import ImageIcon from "@mui/icons-material/Image";

import { useAuth } from "../../AuthProvider";

interface GratiImageUploadProps {
  onImageUploaded?: (snapshot: UploadResult) => void;
  size?: "small" | "medium" | "large";
  storageImageFileName: string; // store the image using this file name
  label?: string;
}

export default function GratiImageUpload(props: GratiImageUploadProps) {
  const { onImageUploaded, storageImageFileName } = props;
  const { imagesRef } = useAuth();

  const label = props.label ? props.label : "Upload Image";
  const size = props.size ? props.size : "medium";
  const uploadInputRef = useRef<HTMLInputElement | null>(null);

  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  useEffect(() => {
    getImage(storageImageFileName).then(() => {
      console.log('Image loaded from storage');
    }).catch((error) => {
      console.error('Error getting image on mount', error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageImageFileName]);

  const getImage = useCallback(async (imageFileName: string) => {
    const logoRef = ref(imagesRef, imageFileName);
    try {
      const url = await getDownloadURL(logoRef);
      setImageUrl(url);
    } catch (error) {
      setImageUrl(null);
    }
  }, [imagesRef]); // Add imagesRef as a dependency

  const deleteImage = function () {
    const logoRef = ref(imagesRef, storageImageFileName);
    deleteObject(logoRef).then(() => {
      setImageUrl(null);
    }).catch((error) => {
      console.error('Error deleting image:', error);
    });
  };

  const uploadImage = function (newLogo: File) {
    const logoRef = ref(imagesRef, storageImageFileName);

    uploadBytes(logoRef, newLogo).then((snapshot) => {
      onImageUploaded && onImageUploaded(snapshot);
      getImage(storageImageFileName).catch((error) => {
        console.error('Error getting image after upload', error);
      });
      console.log('Uploaded a blob or file!');
    }).catch((error) => {
      console.error('Error uploading image:', error);
    });
  };

  //if (!imageUrl && !defaultImageUrl) {
    return (
      <Stack useFlexGap spacing={2} direction="row" sx={{ alignItems: 'center' }}>
        {imageUrl && 
          <Avatar src={imageUrl} sx={{ 
            width: size === "small" ? 40 : size === "medium" ? 60 : 80,
            height: size === "small" ? 40 : size === "medium" ? 60 : 80
          }} />
        }
        <input
          ref={uploadInputRef}
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={(event) => {
            if (event.target.files && event.target.files.length > 0) {
              uploadImage(event.target.files[0]);
            }
          }}
        />
        <Button
          onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
          variant="outlined"
          size="medium"
          color="primary"
          startIcon={<ImageIcon />}
        >
          {imageUrl ? "Change" : "Add " + label}
        </Button>
        {imageUrl && !cancelConfirm &&
          <Button 
            onClick={() => setCancelConfirm(true)}
            variant="text"
            color="secondary"
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>
        }
        {cancelConfirm &&
          <Stack direction="row" spacing={1}>
            <Button variant="text" size="small" color="secondary" onClick={() => { 
                deleteImage();
                setCancelConfirm(false);
            }}
            >
              Confirm Delete
            </Button>
            <Button variant="text" size="small" color="secondary" onClick={() => setCancelConfirm(false)}>
              Undo Delete
            </Button>
          </Stack>
        }
      </Stack>
    );
}
