import { 
  ReactElement, 
  useEffect, 
  useRef, 
  useState 
} from 'react';

import { 
  collection, 
  doc, 
  DocumentReference, 
  orderBy, 
  Query, query,
  Timestamp, 
  where 
} from 'firebase/firestore';

import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import CancelIcon from '@mui/icons-material/Cancel';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import EditIcon from '@mui/icons-material/Edit';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import SaveIcon from '@mui/icons-material/Save';

import { useData } from '../../DataProvider';
import { firestore } from '../../firebase';
import Event from '../../types/Event';
import Person from '../../types/Person';
import Registration from '../../types/Registration';
import { DisplayTime } from '../../utils/utils';
import Hourglass from '../common/Hourglass';
import PersonAutocomplete from '../person/PersonAutocomplete';

interface EventTeeSheetProps {
  eventId: string,
  orgId: string,
  isEditMode?: boolean
}

export default function EventTeeSheet(props: EventTeeSheetProps): ReactElement {
  const { eventId, orgId } = props;

  const { addEventRegistration, isLeagueAdmin, updateEvent } = useData();

  const [isEditingTeesheet, setIsEditingTeesheet] = useState(false);
  const addPersonSelected = useRef<Person | null>(null);
  const [addPersonFieldValue, setAddPersonFieldValue] = useState<Person | null>(null);
  const [teesheets, setTeesheets] = useState<Map<string, string>>(new Map());
  const [event, isEventLoading, isEventError] = useDocumentData<Event>(
    doc(firestore, 'org/' + orgId + '/event/' + eventId) as DocumentReference<Event>
  );

  const qReg = query(
    collection(firestore, 'org/' + orgId + '/event/' + eventId + '/reg'),
    where('isRegistered', '==', true),
    orderBy('playerName')
  ) as Query<Registration>;

  const [registrations, isRegLoading, isRegError] = useCollectionData<Registration>(qReg);

  const initializeTeeSheet = (storedTeeSheets: Record<string, string> | undefined): Map<string, string> => {
    const teesheets: Map<string, string> = new Map();

    if (storedTeeSheets) {
      for (const [key, value] of Object.entries(storedTeeSheets)) {
        teesheets.set(key, value);
      }
    }
    return teesheets;
  };

  useEffect(() => {
    if (isEventError) {
      console.log('Error loading event: ' + isEventError);
    } else {
      if (isEventLoading) {
        console.log('Loading event');
      } else {
        if (event) {
          console.log('Event: ' + event.name);
          setTeesheets(initializeTeeSheet(event.teesheets));
        }
      }
    }
  }, [event, isEventError, isEventLoading]);

  useEffect(() => {
    if (isRegError) {
      console.log('Error loading registrations: ' + isRegError);
    } else {
      if (isRegLoading) {
        console.log('Loading registrations');
      } else {
        if (registrations) {
          console.log('Regs: ' + registrations.length);
        }
      }
    }
    if (isRegLoading) {
      console.log('Loading registrations');
    }
  }, [registrations, isRegError, isRegLoading]);

  useEffect(() => {
    if (teesheets) {
      console.log('Teesheets: ' + teesheets.size);
    }
  }, [teesheets]);

  const handleAddPerson = () => {
    if (event != null && addPersonSelected.current !== null) {
      console.log('Adding person: ' + addPersonSelected.current.name);
      addEventRegistration(event.itemId, true, addPersonSelected.current);
      setAddPersonFieldValue(null);
    }
  };

  const findPlayer = (playerId: string): Registration | null => {
    if (registrations) {
      return registrations.find((reg) => reg.playerId === playerId) || null;
    } else {
      return null;
    }
  };

  const teeTimeIndex = (day: number, group: number, position: number): string => {
    return day + '-' + group + '-' + position;
  };

  const fillSpot = (day: number, group: number, position: number): Registration | null => {
    if (registrations && teesheets) {
      const playerId = teesheets.get(teeTimeIndex(day, group, position));
      if (playerId) {
        const reg = findPlayer(playerId.toString());
        if (reg) {
          console.log('Found: ' + reg.playerName);
          return reg;
        } else {
          console.log('day: ' + day + ' group: ' + group + ' position: ' + position + ' not found.');
          return null;
        }
      } else {
        return null;
      }
      //const player = registrations.find(reg => reg.group === group && reg.groupPosition === position);
      //  }
    } else {
      console.log('Registrations not loaded');
      return null;
    }
  };

  const handleAddSelectionChange = (event: React.SyntheticEvent, option: Person | null /* reason: string */) => {
    if (option !== null) {
      console.log('Selected in Attendeelist: ' + option?.name);
    } else {
      console.log('Selected in Attendeelist: null');
    }
    addPersonSelected.current = option;
  };

  const handleSelectionChange = (day: number, group: number, position: number, value: Registration | null) => {
    const updatedKey = teeTimeIndex(day, group, position);
    if (value) {
      teesheets?.set(updatedKey, value?.playerId);
      // A person has been removed from the tee sheet.
    } else {
      const updatedSheet = [...teesheets.entries()].filter(([key /*, value*/]) => key !== updatedKey);
      setTeesheets(new Map(updatedSheet));
    }
  };

  const handleSubmit = () => {
    console.log('Saving pairings');
    try {
      if (teesheets) {
        updateEvent(eventId, { teesheets: Object.fromEntries(teesheets), isTeeSheetCreated: true });
        setIsEditingTeesheet(false);
      }
    } catch (error) {
      console.log('Error updating pairings: ' + error);
    }
  };

  const handleEditTeesheetCancel = () => {
    // revert the teesheet to the original state
    if (event && teesheets) {
      setTeesheets(initializeTeeSheet(event.teesheets));
    }
    setIsEditingTeesheet(false);
  };

  const TeesheetRow = (props: { reg: Registration | null }): ReactElement => {
    if (props.reg) {
      // TODO: Generalize with Attendee Row
      return (
        <Stack alignItems="center" direction="row" gap={1}>
          <Typography variant="body1">{props.reg.playerName}</Typography>
          {!props.reg.isEighteen && <Typography variant="body1">(9)</Typography>}
          {!props.reg.isPlayingGame && <MoneyOffIcon />}
          {!props.reg.isRiding && <DirectionsWalkIcon />}
        </Stack>
      );
    } else {
      return <Typography variant="body1">---</Typography>;
    }
  };

  const TeesheetGrid = (props: { numGroups: number; numInGroup: number }): ReactElement => {
    const day = 0;
    return (
      <Grid container size={12} sx={{px: 0}}>
        {Array.from({ length: props.numGroups }, (_, group) => (
          <Grid container key={'group' + group} justifyContent="flex-start" size={12} sx={{mb: 1 }}>
            <Grid 
              size={{ xs: 3, sm: 3, md: 3, lg: 2 }} 
              sx={{mr: 1, pl: 1, pt: 1, backgroundColor: "primary.container", color: "primary.onContainer"}}>
              {event && (
                <Box>
                  <Typography variant="body1">
                    {DisplayTime(
                      Timestamp.fromMillis(event.datetime.toMillis() + 600000 * (event.isShotgun ? 0 : group)),
                      'America/Los_Angeles'
                    )}
                  </Typography>
                </Box>
              )}
            </Grid>
            <Grid container size="grow">
              {Array.from({ length: props.numInGroup }, (_, position) => (
                <Grid size={{ xs: 12 }} key={'group' + group + 'pos' + position}>
                  {registrations && isEditingTeesheet ? (
                    <Autocomplete
                      onChange={(event, value) => {
                        handleSelectionChange(day, group, position, value);
                      }}
                      disablePortal
                      id={'group' + group + 'position' + position}
                      defaultValue={fillSpot(day, group, position)}
                      options={registrations}
                      getOptionLabel={(option: Registration) => option.playerName}
                      renderOption={(props: object, option: Registration /*, state: object*/) => (
                        <div {...props}>{option.playerName + (option.isEighteen ? '' : ' (9)')}</div>
                      )}
                      renderInput={(params) => <TextField {...params} label="Name" />}
                    />
                  ) : (
                    <TeesheetRow reg={fillSpot(day, group, position)} />
                  )}
                </Grid>
              ))}
            </Grid>
          </Grid>
        ))}
      </Grid>
    );
  };
  if (!isEventLoading && event) {
    return (
      <Container
        component="form"
        disableGutters
        sx={{ flexGrow: 1, pl: 0 }}
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Typography color="primary.main" variant="h5" component="h5" gutterBottom>
          Tee Sheet
        </Typography>
        <Stack sx={{ width: "100%", display: "flex", flexGrow: 1}}>
          <TeesheetGrid numGroups={event.numTeeTimes} numInGroup={4} />
          <Stack direction="row" spacing={1} sx={{mb: 1, color:"primary.light"}}>
            <Typography variant="body1">
              (9) = 9 holes
            </Typography>
            <Stack direction="row">
              <DirectionsWalkIcon />
              <Typography variant="body1">= Walking</Typography>
            </Stack>
            <Stack direction="row">
              <MoneyOffIcon />
              <Typography variant="body1">= Not in game</Typography>
            </Stack>
          </Stack>
        </Stack>
        {isLeagueAdmin(event.leagueId) && (
          <Stack>
            <Stack direction="row" spacing={2}>
              {isEditingTeesheet ? (
                <>
                  <Button
                    type="submit"
                    key="formSubmit"
                    size="medium"
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                  >
                    Save
                  </Button>
                  <Button
                    type="button"
                    key="formCancel"
                    size="medium"
                    variant="outlined"
                    color="secondary"
                    startIcon={<CancelIcon />}
                    onClick={() => handleEditTeesheetCancel()}
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <Button
                  type="button"
                  key="formEdit"
                  size="medium"
                  variant="contained"
                  color="primary"
                  startIcon={<EditIcon />}
                  onClick={() => setIsEditingTeesheet(true)}
                >
                  Edit
                </Button>
              )}
            </Stack>
            <Stack direction="row" spacing={1}
              sx={{display: "flex", justifyContent: "flex-start", alignItems: "center", my: 1 }}>
              <PersonAutocomplete
                id="addPerson"
                size="small"
                label="Person to add"
                selectedPersonId={addPersonFieldValue ? addPersonFieldValue.itemId : ''}
                handleOnChange={handleAddSelectionChange}
              />
              <Button
                type="button"
                variant="outlined"
                color="secondary"
                size="medium"
                onClick={() => handleAddPerson()}
              >
                Add
              </Button>
            </Stack>
          </Stack>
        )}
      </Container>
    );
  } else {
    return <Hourglass />;
  }
}
