import { ReactElement, useEffect } from 'react';

import { collection, orderBy, Query, query, where } from 'firebase/firestore';

import { useCollectionData } from 'react-firebase-hooks/firestore';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

import { useAuth } from '../../AuthProvider';
import { useData } from '../../DataProvider';
import { firestore } from '../../firebase';
import UserProfile from '../../types/UserProfile';
import Hourglass from '../common/Hourglass';

import PersonListItem from './PersonListItem';

interface PersonDirectoryProps {
  isActive: boolean;
}

export default function PersonDirectory(props: PersonDirectoryProps): ReactElement {
  const { isRevealingGodsSecrets } = useAuth();
  const { adminOrgs, isOrgAdmin } = useData();

  const qMembers = query(
    collection(firestore, 'profile'),
    where('isActive', '==', props.isActive),
    orderBy('name')
  ) as Query<UserProfile>;

  const [members, isLoading, isError] = useCollectionData<UserProfile>(qMembers);

  useEffect(() => {}, [members]);

  if (isLoading) {
    return <Box width="100vw" />;
  } else if (isError) {
    return <Box width="100vw" />;
  } else if (members) {
    return (
      <Container disableGutters className="SectionContainer">
        <Typography align="left" gutterBottom variant="headline">
          {adminOrgs && isOrgAdmin(adminOrgs[0])
            ? props.isActive
              ? 'Active Members'
              : 'Guests'
            : 'Member Directory'}
        </Typography>
        <Box>
          <List
            sx={{
              width: '100%',
              height: '100%',
              position: 'relative',
              overflow: 'auto',
              p: 0,
              '& ul': { padding: 0 },
              '& li': { padding: 0 },
            }}
            subheader={<li />}
          >
            { members.flatMap((member: UserProfile) => {
              if (isRevealingGodsSecrets || !member?.isTest) {
                return (
                  <PersonListItem
                    key={member.itemId} 
                    person={member} 
                    isDividerDisplayed={true}
                  />
                );
              } else {
                return null;
              }
            })}
          </List>
        </Box>
      </Container>
    );
  } else {
    return <Hourglass />;
  }
};
