import { 
  ReactElement, 
  useEffect, 
  useState } from "react";

import { ref } from "firebase/storage";

import { Link } from "react-router-dom";

import { useDownloadURL } from "react-firebase-hooks/storage";

import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { useAuth } from "../../AuthProvider";
import Person from '../../types/Person';
import {
  FormatPhoneNumber, 
  GetFirstAndLastName 
} from "../../utils/utils";

interface PersonRowProps {
  person: Person,
  checkEmail?: boolean,
  isDividerDisplayed?: boolean,
  actions?: ReactElement<typeof Stack>
}

export default function PersonRow(props: PersonRowProps): ReactElement {
  const { checkEmailAddressExists, imagesRef } = useAuth();

  const { person, checkEmail, isDividerDisplayed, actions } = props;
  const [imageUrl, isImageLoading] = useDownloadURL((person && person.picture && person.picture.length > 0) ? ref(imagesRef, person.picture) : null);
  const [isEmailAvailable, setIsEmailAvailable] = useState(true);

  useEffect (() => {
    if (person.email === "" || !checkEmail) {
      return;
    } else {
      const checkEmailExists = async () => {
        const isExists = await checkEmailAddressExists(person.email);
        setIsEmailAvailable(!isExists);
      }
      checkEmailExists();
    }
  }, [person.email, checkEmail, checkEmailAddressExists]);

  const StyledName = (): ReactElement => {
    const [firstName, lastName] = GetFirstAndLastName(person.name);
    
    return (
        <Stack 
          direction="column" 
          spacing={0} 
          sx={{alignItems: "flex-start", width: {xs: '6rem', sm: '8rem', md: '12rem', }}}
        >
          <Typography variant="h6" sx={{display: {xs: 'block', md: 'none'}}}>
            {firstName}
          </Typography>
          <Typography variant="overline" sx={{display: {xs: 'block', md: 'none'},}}>
            {lastName}
          </Typography>
          <Typography variant="h6" sx={{display: {xs: 'none', md: 'block'}}}>
            {firstName + " " + lastName}
          </Typography>
        </Stack>
    );
  }

  const StyledBasicInfo = (): ReactElement => {
    return (
      <Stack direction="column" spacing={0} sx={{alignItems: "flex-start"}}>
        <Typography variant="body1" sx={{fontFamily: {xs: 'Roboto Condensed', sm: 'roboto'}}}>
          {person.email}
        </Typography>
        { checkEmail && isEmailAvailable === false &&
          <Alert severity="error" sx={{py: 0, px: 0, my: 0, lineHeight: 1 }}>
            This email address is in use.
          </Alert>
        }
        { person.phone &&
          <Typography variant="body1" sx={{fontFamily: {xs: 'Roboto Condensed', sm: 'roboto'}}}>
            {FormatPhoneNumber(person.phone)}
          </Typography>
        }
        { person.ghin && person.ghin !== "" &&
          <Typography variant="body1" sx={{fontFamily: {xs: 'Roboto Condensed', sm: 'roboto'}}}>
            {"GHIN: " + person.ghin + ", Index: " + person.index}
          </Typography>
        }
      </Stack>
    );
  }
// something to insert later: <Grid item container component={NavLink} to="/person" state={{personId: person.itemId}} xs={4}></Grid>

return (
  <ListItem 
    component={Link} 
    to={`/profile/${person.itemId}`} 
    divider={isDividerDisplayed}
    sx={{ display: "flex", flexDirection: "row", alignItems: "center"  }} 
  >
    <ListItemAvatar sx={{display: {xs: "none", sm: "flex"}}}>
      <Avatar 
        alt={person.name} 
        src={(!isImageLoading && imageUrl) ? imageUrl : ""}
      />
    </ListItemAvatar>
    <ListItemAvatar sx={{color: "surface.contrastText"}}>
      <StyledName />
    </ListItemAvatar>
    <ListItemText>
      <StyledBasicInfo />
    </ListItemText>
    {actions &&
      <ListItemText>
        {actions}
      </ListItemText>
    }
  </ListItem>);
}