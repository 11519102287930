import { ReactElement } from "react";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const WeeklyPlay = (): ReactElement => {
  return (
<Box>
  <Typography variant="body1">
    <b>WEEKLY COMPETITIONS</b> will begin in May and will end on the last Wednesday before the final general meeting in 
    September. Any play on Wednesday prior to May 1st or after the final general meeting in September will not be 
    considered to be a “weekly competition.” It will be a “play day.” No money will be paid, either for weekly games or 
    any side games. These play days will not be considered for eclectic scores. Any hole-in-one made on any play day 
    will be recognized. The guidelines set out for weekly competitions as they relate to sign ups, cancellations, tee 
    times and frost delays will also apply to “play days.” Games arranged on a “to be determined” date during the entire 
    yearly schedule will be considered to be “play days” and any hole-in-one on one of these dates will be recognized. 
    Participation in any of these play days will qualify as club participation for team play.
  </Typography>
  <Typography variant="body1">
    <b>SIGN UP</b> via the <a href="https://swga.golf" target="_blank" rel="noreferrer">SWGA.golf</a> website. Sign up opens Thursday morning and the deadline is 5pm on the Sunday prior to Wednesday play. You may sign up by going to <a href="swga.golf" target="_blank">SWGA.golf</a> and following the instructions to sign up. The weekly email will include a link to the website for ease of sign-up. TEE TIMES, PAIRINGS & DAY&apos;S PLAY will be available on the website the day before play. This site will include information about side games such as Bingo Bango Bongo, Skins, etc. These games are always optional. There is a limit to the number of tee times and, therefore the number of ladies that can play. Slots will be filled in order that ladies have signed up. If you sign-up after the tee sheet is full, you will be put on a waitlist and notified if someone cancels.
  </Typography>
  <Typography variant="body1">
    If you have any questions about the day&apos;s play or run into problems signing up, please contact Debbie Ferguson at debbie@swga.golf. 
  </Typography>
  <Typography variant="body1">
    <b>CANCELLATIONS</b> can be made on the website by going to the event, clicking &quot;Cancel Registration&quot; and then &quot;Confirm&quot;. Once you confirm cancelation, your slot will be given to the next person on the wait list. If you must cancel after Monday before the weekly play day, please call the appropriate pro shop: Meadows Pro Shop @ 541-593-4828 or Woodlands Pro Shop @ 541-593-3495 no later than one hour before first scheduled tee time. PLEASE NOTE: Members who sign up to play and do not cancel appropriately will be considered a “No Show.” No Shows, and any members that consistently sign up and then cancel on a chronic basis, will be reported to the board for review.
  </Typography>
  <Typography variant="body1">
    <b>TEE TIMES, PAIRINGS & DAY&apos;S PLAY</b> will be available on the website and sent via email to all current members on the Monday before play. This email will also include any side games (KP, long drive, etc.). Please arrive NO LATER than 30 minutes before your scheduled tee time. Pairings are determined by the weekly play coordinator, considering a wide range of factors. If you don&apos;t like your pairing, complain to Cathy. If you love it, send praise to Debbie.
  </Typography>
  <Typography variant="body1">
    <b>FROST DELAY</b> is common for Central Oregon. Please check for frost delays by calling the frost line @ 541-593-4402. Remember: a frost delay is a moving target, so it is imperative that you arrive no later than 30 minutes before the delayed tee time.
  </Typography>
  <Typography variant="body1">
    <b>CHECK IN</b> at the pro shop upon your arrival. If you are playing the optional game,  please give your $5 to the person at the pro shop. If you don&apos;t pay $5, you will not be eligible for the optional game.
  </Typography>
  <Typography variant="body1">
    <b>PLAY READY GOLF</b> means being ready to play, not playing when you&apos;re ready. Make your club selection as you approach your ball. Pick up your ball once you reach your max posting score. Keep pace with the group in front. If your group is behind, proceed to next hole as soon as you hole out rather than waiting for the entire group to finish.
  </Typography>
  <Typography variant="body1">
    <b>RESULTS/PAYOUTS</b>: The Competition chair is responsible for posting the results of each play-day competition on the website with a summary sent via e-mail. All monies awarded will be paid at the end of the season.
  </Typography>
  <Typography variant="body1">
    <b>SCORECARDS</b> will be distributed by the pro shop at sign in. Remember, scorecards must be exchanged. At the end of the round, cards must be signed by the scorer and attested by another player (failure to do so may result in disqualification for the round). After completion of your round, turn in your scorecard to either the pro shop or to the weekly competition committee as soon as possible to allow them to begin computations. You must turn in your scorecard even if you are not participating in the weekly competition as you will still be playing for Eclectic and Hole-in-one. Scores will be posted by the weekly competition chair on GHIN.
  </Typography>
  <Typography variant="body1">
    <b>ECLECTIC TOURNAMENT</b> posting is computed each week by the weekly play chair. Results will be shared at the end of season meeting. The object of the game is to score as low as possible on each hole. The final total score will consist of the LOWEST score on each separate hole throughout the season. The handicap revision date that is used is the last day of weekly competition.
  </Typography>
  <Typography variant="body1">
    <b>POSTING ADJUSTED GROSS SCORE</b> will be done each week using Golf Genius by the Weekly Play Coordinator, Handicap Committee, Tournament Chairs or Special Event organizers. Ladies DO NOT need to post their own scores when playing in an SWGA event.
  </Typography>
  <Typography variant="body1">
    <b>NET DOUBLE BOGEY</b> (or NDB) (Double Bogey Plus): Net double bogey is used when a player&apos;s actual score or most likely score exceeds a maximum number derived using the following formula:
  </Typography> 
  <Typography variant="body1">
    <code>NDB = Par + 2 + handicapped strokes received or given on a hole.</code>
  </Typography>
  <Typography variant="body1">
    <b>MOST LIKELY SCORE</b> (or MLS) is used whenever you start but do not finish a hole, no matter the reason. MLS is the number of strokes you&apos;ve already taken, plus the number of strokes it would take you to finish the hole most of the time. MLS is used for a hole not finished. The score cannot exceed Net Double Bogey.
  </Typography>
  <Typography variant="body1">
    <b>NET PAR</b> is used whenever you do not play a hole or a hole is not played under the rules of golf (e.g. you take a mulligan). Net par  =  Par of hole + any handicap strokes received on that hole.
  </Typography>
  <Typography variant="body1">
    <b>GOLF RULES (USGA)</b> will be followed and golf etiquette practiced. If there is a question regarding a ruling, play two balls until you finish that hole, then check with the pro on duty after you finish the round (notify Handicap Chair of same). Each player should carry a rules book when playing and attempt to become familiar with common rules situations.
  </Typography>
  <Typography variant="body1">
    <b>LOST BALL/OUT-OF-BOUNDS BALL</b>, Two Stroke Penalty When a player&apos;s ball has not been found or is known or virtually certain to be out of bounds, the player may proceed under the Stroke and Distance Local Rule for a penalty of two strokes, rather than proceeding under stroke and distance. The ball may be dropped on the fairway being played (within two club lengths of the fairway edge) no nearer to the hole than where the original ball is estimated to have come to rest or last crossed the edge of the course boundary to go out of bounds. This local rule is not available if a provisional ball has been played. The search time is now THREE MINUTES and begins once the player begins the search. After three minutes the ball is lost and cannot be played even if it is later found.
  </Typography>
  <Typography variant="body1">
    <b>SPORTSMANSHIP:</b> Rule 1.2a defines the conduct expected of all players. All players are expected to play in the spirit of the game, act with integrity, show consideration of others, and take good care of the course. The new rules of golf give the committee the right to not only disqualify the player for serious misconduct but they also may impose penalties, such as a general penalty or one-stroke penalty. (Rule 1.2b). If a player consistently disregards etiquette guidelines, the Executive Board will verbally notify the player on the first complaint. On the second complaint, the player will get written notification and on the third complaint the player will receive written notification of disciplinary action. Such actions may include imposing penalty strokes or prohibiting play on play days. A serious breach of etiquette during either the Championship or Partnership events will prompt the Board to consider disqualifying the player.
  </Typography>
  <Typography variant="body1">
    <b>ABNORMAL COURSE CONDITIONS</b> (including immovable obstructions that are close to the putting green) (Sprinkler Heads) In addition to the types of abnormal course conditions described in Rule 16.1a when a ball lies in the general area, interference also exists if an abnormal course condition is on the player’s line of play, is within two club-lengths of the putting green, and is within two club-lengths of the ball. The player may take relief under Rule 16.1b. Exception-There is no relief under this local rule if the player chooses a line of play that is clearly unreasonable. The ball must be lifted and dropped in the relief area from knee height at a point that is in the general area, not nearer the hole than the reference point and there must be complete relief from the interference by the abnormal course condition (Sprinkler Head).
  </Typography>
  <Typography variant="body1">
    <b>TIES</b>, unless explicitly described in the weekly game rules, will be broken using the USGA Recommendations. Score cards will be matched to determine the winner on the basis of the best scores matched as follows: Back Nine – Holes 10-18, Back Six – Holes 13-18, Back Three – Holes 16-18, Hole 18. If the scores are still tied after matching score cards as noted above, the Committee shall match the #1 Handicap Hole then #2 Handicap Hole, and continue in this manner until the winner has been determined.
  </Typography>
</Box>
);
}